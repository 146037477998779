import { useMutation } from "react-query";
import request from "../requests/request";

const marcaServices = {
  createParametroDePrecios: async ({ data }) => {
    const response = await request({
      method: "POST",
      url: "/api/marcas/parametros/create/",
      data,
    });
    return response.data;
  },
  updateParametroDePrecios: async ({ data }) => {
    const response = await request({
      method: "PUT",
      url: "/api/marcas/parametros/update/",
      data,
    });
    return response.data;
  },
};

export const useCreateParametroDePrecios = ({ queryParams, queryProps } = {}) =>
  useMutation({
    mutationFn: (data) =>
      marcaServices.createParametroDePrecios({ ...queryParams, data }),
    ...queryProps,
  });

export const useUpdateParametroDePrecios = ({ queryParams, queryProps } = {}) =>
  useMutation({
    mutationFn: (data) =>
      marcaServices.updateParametroDePrecios({ ...queryParams, data }),
    ...queryProps,
  });
