import Alert from "@material-ui/lab/Alert";
import React from "react";

export default function AlertInformativo({ tipo_coeficiente }) {
  return (
    <Alert severity="info">
      {`Acá podés ver los coeficientes asociados al ${tipo_coeficiente}. Estos valores son solo
      informativos y no modifican la configuración principal. Si necesitás,
      podés sumar nuevos coeficientes.`}
    </Alert>
  );
}
