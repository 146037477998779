import React from "react";
import request from "../../requests/request";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../components/Notifications";
import { Switch } from "@material-ui/core";

export async function toggleEnabledParametros(
  marca,
  proveedor,
  update,
  setOpenBackdrop,
) {
  setOpenBackdrop(true);
  try {
    const response = await request({
      method: "PUT",
      url: "/api/marcas/parametros/toggle-enabled/",
      params: { marca, proveedor },
    });
    if (response.status === 201) {
      setOpenBackdrop(false);
      update();
      response.data.data.is_enabled
        ? successNotification("Parametros habilitados!")
        : warningNotification("Parametros deshabilitados!");
    } else {
      setOpenBackdrop(false);
      errorNotification("No se pueden habilitar los parametros.");
    }
  } catch (e) {
    setOpenBackdrop(false);
    console.error(e);
    errorNotification("No se pueden habilitar los parametros.");
  }
}

export async function getParametros(idProveedor) {
  const response = await request({
    method: "GET",
    url: `/api/proveedor/parametros/${idProveedor}/`,
  });
  if (response.status === 200) {
    return response.data.data;
  } else {
    return null;
  }
}

export const defaultParametrosValues = (parametrosProveedor) => ({
  parametro_id: parametrosProveedor.id || null,
  porcentaje_contado: parametrosProveedor.porcentaje_contado || "0.00000",
  porcentaje_descuento: parametrosProveedor.porcentaje_descuento || "0.00000",
  porcentaje_lista: parametrosProveedor.porcentaje_lista || "0.00000",
});

export const proveedorUseListaPrecio = (parametrosProveedor) => {
  // Valida si el proveedor tiene activado is_precio_lista en base a la
  // lista de precios (parametrosProveedor). parametrosProveedor puede ser
  // nulo, esto ocurre solo si is_precio_lista está activado en el proveedor.
  if (parametrosProveedor) {
    return parametrosProveedor.is_precio_lista;
  }
  return true;
};
