import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  getRanHex,
  getTipoCoeficiente,
  getTituloModal,
  TIPO_COEFICIENTE,
} from "./utils";
import ButtonCancelar from "../Button/ButtonCancelar";
import TablaCoeficiente from "./componentes/TablaCoeficiente";
import FormularioNuevoCoeficiente from "./componentes/FormularioNuevoCoeficiente";
import AlertInformativo from "./componentes/AlertInformativo";
import { useFormik, validateYupSchema } from "formik";
import validationSchema from "./componentes/FormularioNuevoCoeficiente/validationSchema";
import {
  useBajaDescuentoYRecargo,
  useCreateDescuentoYRecargo,
  useGetDescuentoYRecargo,
} from "../../../services/genericos";
import Alert from "@material-ui/lab/Alert";
import AlertWarningCoeficienteConfigurado from "./componentes/AlertWarningCoeficienteConfigurado";
import { errorNotification } from "../../Notifications";

export default function ModalDescuentoYRecargo({
  open = false,
  close = () => {},
  onChangeCoeficiente = () => {},
  marca = null,
  repuesto_proveedor = null,
  parametros_proveedor = null,
  tipo_coeficiente = null,
  coeficienteConfigurado,
  coeficientesCargados = [],
}) {
  const formik = useFormik({
    initialValues: {
      nombre: "",
      coeficiente: "",
    },
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      let data = {
        ...values,
        marca_id: marca ? marca.id : null,
        repuesto_proveedor_id: repuesto_proveedor
          ? repuesto_proveedor.idRepuestoProveedor
          : null,
        parametros_proveedor_id: parametros_proveedor
          ? parametros_proveedor.id
          : null,
        tipo_coeficiente: tipo_coeficiente,
      };
      fakeCreateDescuentoYRecargo.mutate(data);
    },
  });

  // Se crea fakeGetDescuentoYRecargo para simular el resultado de la consulta
  const fakeGetDescuentoYRecargo = {
    data: coeficientesCargados.filter(
      (item) =>
        item.tipo_coeficiente === tipo_coeficiente && item.activo === true,
    ),
    isLoading: false,
    isFetching: false,
  };

  // Se crea fakeBajaDescuentoYRecargo para simular la baja de un coeficiente
  const fakeBajaDescuentoYRecargo = {
    // reveer
    mutate: (id) => {
      onChangeCoeficiente(
        coeficientesCargados.map((item) =>
          item.id === id ? { ...item, activo: false } : item,
        ),
      );
    },
    mutateAsync: async (id) => {
      onChangeCoeficiente(
        coeficientesCargados.map((item) =>
          item.id === id ? { ...item, activo: false } : item,
        ),
      );
    },
    isLoading: false,
    isFetching: false,
  };

  // Se crea fakeCreateDescuentoYRecargo para simular la creación de un coeficiente
  // Se agrega el coeficiente creado a la lista de coeficientes cargados
  // el coeficiente creado se marca como nuevo para poder diferenciar en el backend
  const fakeCreateDescuentoYRecargo = {
    mutate: (values) => {
      onChangeCoeficiente([
        ...coeficientesCargados,
        { ...values, id: getRanHex(8), activo: true, nuevo: true },
      ]);
      formik.resetForm();
    },
    mutateAsync: async (values) => {
      onChangeCoeficiente([
        ...coeficientesCargados,
        { ...values, id: getRanHex(8), activo: true, nuevo: true },
      ]);
      formik.resetForm();
    },
    isLoading: false,
    isFetching: false,
  };

  return (
    <Dialog
      maxWidth="md"
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          {`${getTituloModal({ marca, repuesto_proveedor, parametros_proveedor, tipo_coeficiente })}`}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 20 }}>
        <AlertInformativo
          tipo_coeficiente={getTipoCoeficiente({ tipo_coeficiente })}
        />
        <Box mt={2} />
        <FormularioNuevoCoeficiente
          formik={formik}
          createDescuentoYRecargo={fakeCreateDescuentoYRecargo}
        />
        <Box mt={2} />
        <TablaCoeficiente
          getDescuentoYRecargo={fakeGetDescuentoYRecargo}
          bajaDescuentoYRecargo={fakeBajaDescuentoYRecargo}
        />
        <Box mt={2} />
        <AlertWarningCoeficienteConfigurado
          coeficienteConfigurado={coeficienteConfigurado}
          getDescuentoYRecargo={fakeGetDescuentoYRecargo}
          tipo_coeficiente={tipo_coeficiente}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <ButtonCancelar message={"Salir"} click={close} />
      </DialogActions>
    </Dialog>
  );
}
