import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import DescuentoYRecargoButton from "../../../../components/ait-reusable/DescuentoYRecargoButton";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import { Alert } from "@material-ui/lab";
import { TIPO_COEFICIENTE } from "../../../../components/ait-reusable/ModalDescuentoYRecargo/utils";
import TablaDescuentosYRecargos from "../../../../components/ait-reusable/TablaDescuentosYRecargos";

export default function DescuentoYRecargos({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
}) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        justifyContent: "center",
        flexWrap: "nowrap",
        marginBottom: 4,
      }}
    >
      <Box
        p={2}
        // item
        hidden={values.is_precio_lista}
        // sm={3}
        // xs={12}
        width={"100%"}
      >
        <Typography variant="body1">Recargos mayoristas</Typography>
        <Box mt={1} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          style={{ gap: 2 }}
        >
          <TextField
            placeholder="Ej:10"
            name="recargo_lista"
            label="Recargo Mayorista"
            maxLength={4}
            fullWidth
            type="number"
            variant="outlined"
            size="small"
            autoComplete="off"
            value={values.recargo_lista}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: "%",
            }}
          />
          <DescuentoYRecargoButton
            asIcon
            tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_LISTA}
            coeficienteConfigurado={values.recargo_lista}
            repuesto_proveedor={values}
            onChangeCoeficiente={async (updatedItems) =>
              await setFieldValue(
                "descuentos_recargos_informativos",
                updatedItems,
              )
            }
            coeficientesCargados={values.descuentos_recargos_informativos}
          />
        </Box>
        <Box mt={1} />
        <TablaDescuentosYRecargos
          showAlert
          coeficientePrincipal={values.recargo_lista}
          coeficientesCargados={values.descuentos_recargos_informativos.filter(
            (coef) =>
              coef.tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_LISTA,
          )}
          tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_LISTA}
          onAplicar={async (value) => {
            await setFieldValue("recargo_lista", value);
          }}
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        p={2}
        // item
        // xs={12}
        // sm={!values.is_precio_lista ? 3 : 3}
        width={"100%"}
      >
        <Typography variant="body1">Descuentos</Typography>
        <Box mt={1} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          style={{ gap: 2 }}
        >
          <TextField
            placeholder="Ej:10"
            name="descuento"
            maxLength={4}
            variant="outlined"
            label="Descuento"
            fullWidth
            size="small"
            autoComplete="off"
            type="number"
            value={values.descuento}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: "%",
            }}
          />
          <DescuentoYRecargoButton
            asIcon
            tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO}
            coeficienteConfigurado={values.descuento}
            repuesto_proveedor={values}
            onChangeCoeficiente={async (updatedItems) =>
              await setFieldValue(
                "descuentos_recargos_informativos",
                updatedItems,
              )
            }
            coeficientesCargados={values.descuentos_recargos_informativos}
          />
        </Box>
        <Box mt={1} />
        <TablaDescuentosYRecargos
          showAlert
          coeficientePrincipal={values.descuento}
          coeficientesCargados={values.descuentos_recargos_informativos.filter(
            (coef) =>
              coef.tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO,
          )}
          tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO}
          onAplicar={async (value) => {
            await setFieldValue("descuento", value);
          }}
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box
        p={2}
        // item
        // xs={12}
        // sm={!values.is_precio_lista ? 3 : 3}
        width={"100%"}
      >
        <Typography variant="body1">Recargos de contado</Typography>
        <Box mt={1} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          style={{ gap: 2 }}
        >
          <TextField
            placeholder="Ej:10"
            name="recargo_contado"
            label="Recargo Contado"
            fullWidth
            maxLength={4}
            autoComplete="off"
            variant="outlined"
            size="small"
            type="number"
            value={values.recargo_contado}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: "%",
            }}
          />
          <DescuentoYRecargoButton
            asIcon
            tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_CONTADO}
            repuesto_proveedor={values}
            coeficienteConfigurado={values.recargo_contado}
            onChangeCoeficiente={async (updatedItems) =>
              await setFieldValue(
                "descuentos_recargos_informativos",
                updatedItems,
              )
            }
            coeficientesCargados={values.descuentos_recargos_informativos}
          />
        </Box>
        <Box mt={1} />

        <TablaDescuentosYRecargos
          showAlert
          coeficientePrincipal={values.recargo_contado}
          coeficientesCargados={values.descuentos_recargos_informativos.filter(
            (coef) =>
              coef.tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_CONTADO,
          )}
          tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_CONTADO}
          onAplicar={async (value) => {
            await setFieldValue("recargo_contado", value);
          }}
        />
      </Box>
    </Grid>
  );
}
