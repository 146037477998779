import { useQuery, useMutation } from "react-query";
import request from "../requests/request";

const genericosServicers = {
  getDescuetosYRecargos: async ({ ...params }) => {
    const { data } = await request({
      url: "/api/genericos/descuento_recargo/list/",
      params,
    });
    return data;
  },
  createDescuentoYRecargo: async ({ data }) => {
    const response = await request({
      url: "/api/genericos/descuento_recargo/create/",
      method: "POST",
      data: data,
    });
    return response.data;
  },
  bajaDescuentoYRecargo: async (id) => {
    const response = await request({
      url: `/api/genericos/descuento_recargo/delete/${id}/`,
      method: "PUT",
    });
    return response.data;
  },
};

export const useGetDescuentoYRecargo = ({ queryParams, queryProps } = {}) => {
  return useQuery({
    queryKey: ["getDescuetosYRecargos", queryParams],
    queryFn: () => genericosServicers.getDescuetosYRecargos({ ...queryParams }),
    initialData: [],
    ...queryProps,
  });
};

export const useCreateDescuentoYRecargo = ({
  queryParams,
  queryProps,
} = {}) => {
  return useMutation({
    mutationFn: (data) =>
      genericosServicers.createDescuentoYRecargo({ ...queryParams, data }),
    ...queryProps,
  });
};

export const useBajaDescuentoYRecargo = ({ queryParams, queryProps } = {}) => {
  return useMutation({
    mutationFn: (id) => genericosServicers.bajaDescuentoYRecargo(id),
    ...queryProps,
  });
};
