import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableMarcaRow from "./TableMarcaRow";
import Spinner from "../../../../components/Spinner";
import NoResults from "../../../../components/ait-reusable/NoResults";
import Pagination from "react-js-pagination";
import { Box } from "@material-ui/core";

const HeaderTableCell = withStyles((theme) => ({
  root: {
    color: "#fff",
    fontWeight: "bolder",
  },
}))(TableCell);

export default function TableMarcas({
  marcas,
  parametrosProveedor,
  loading = false,
  handleSubmit,
  update,
  setOpenBackdrop,
  onPageChange,
  page,
  numeroItems,
}) {
  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#007bff",
              }}
            >
              <HeaderTableCell />
              <HeaderTableCell>Nombre</HeaderTableCell>
              <HeaderTableCell align="center">Descuento</HeaderTableCell>
              <HeaderTableCell align="center">Recargo contado</HeaderTableCell>
              <HeaderTableCell align="center">Recargo Lista</HeaderTableCell>
              <HeaderTableCell align="center">
                Parametros habilitados
              </HeaderTableCell>
              <HeaderTableCell align="center">Acciones</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marcas.map((marca) => (
              <TableMarcaRow
                key={marca.id}
                marca={marca}
                parametrosProveedor={parametrosProveedor}
                handleSubmit={handleSubmit}
                update={update}
                setOpenBackdrop={setOpenBackdrop}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : marcas.length === 0 ? (
        <NoResults noResult={false} />
      ) : null}
      <Box
        mt={2}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={numeroItems}
          pageRangeDisplayed={5}
          onChange={(newPage) => onPageChange(newPage)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </Box>
    </>
  );
}
