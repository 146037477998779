import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Badge, IconButton, Modal, Button } from "@material-ui/core";
import useDisclosure from "../../customHooks/useDisclosure";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ModalDescuentoYRecargo from "./ModalDescuentoYRecargo";
import PostAddIcon from "@material-ui/icons/PostAdd";
const BadgeWrapper = ({ children, showBadge, badgeProps }) => {
  if (!showBadge) return children;
  return (
    <Badge color="primary" {...badgeProps}>
      {children}
    </Badge>
  );
};

export default function DescuentoYRecargoButton({
  tipo_coeficiente,
  marca,
  repuesto_proveedor,
  proveedor,
  coeficienteConfigurado,
  badge,
  badgeProps,
  onChangeCoeficiente = () => {},
  asIcon = false,
  buttonText = "",
  coeficientesCargados = [],
  ...props
}) {
  const [opened, { open, close }] = useDisclosure();

  const ButtonWrapper = asIcon ? IconButton : Button;

  const openModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    open();
  };

  return (
    <>
      {asIcon ? (
        <ButtonWrapper onClick={openModal} {...props} type="button">
          <BadgeWrapper showBadge={badge} badgeProps={badgeProps}>
            <PostAddIcon />
          </BadgeWrapper>
        </ButtonWrapper>
      ) : (
        <BadgeWrapper showBadge={badge} badgeProps={badgeProps}>
          <ButtonWrapper onClick={openModal} {...props} type="button">
            {`${buttonText}`}
          </ButtonWrapper>
        </BadgeWrapper>
      )}
      {opened && (
        <ModalDescuentoYRecargo
          open={opened}
          close={close}
          marca={marca}
          proveedor={proveedor}
          repuesto_proveedor={repuesto_proveedor}
          tipo_coeficiente={tipo_coeficiente}
          coeficienteConfigurado={coeficienteConfigurado}
          onChangeCoeficiente={onChangeCoeficiente}
          coeficientesCargados={coeficientesCargados}
        />
      )}
    </>
  );
}
