import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import request from "../../requests/request";
import { makeStyles } from "@material-ui/core/styles";
import CircularBackdrop from "../../components/ait-reusable/CircularBackdrop";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import {
  Grid,
  Container,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Box,
  Paper,
} from "@material-ui/core";
import PageHeader from "../../components/ait-reusable/PageHeader";
import { defaultParametrosValues, getParametros } from "./utils";
import TableMarcas from "./componentes/TableMarcas";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "../../customHooks/useDebounce";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "1em",
  },
  noChecked: {
    backgroundColor: "##f1eeee",
  },
  checked: {
    backgroundColor: "#daecce",
  },
  media: {
    height: 64,
    width: 64,
    margin: 0,
  },
  image: {
    textAlign: "center",
  },
  marcaContainer: {
    overflowY: "scroll",
    maxHeight: "500px",
  },
});

const Marcas = () => {
  let { idProveedor } = useParams();
  const history = useHistory();
  const [marcas, setMarcas] = useState([]);
  const [parametros, setParametros] = useState(null);
  const [parametrosProveedor, setParametrosProveedor] = useState(null);
  const [marcaSelected, setMarcaSelected] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [params, setParams] = useState({
    page: 1,
    itemsPerPage: 10,
    q: "",
  });
  const [numeroItems, setNumeroItems] = useState(0);
  const searchDebounce = useDebounce(search, 500);

  const handleSubmit = async (
    values,
    marca = null,
    proveedor = null,
    forcePost = false,
  ) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: parametros && !forcePost ? "PUT" : "POST",
        url: `/api/marcas/parametros/${
          parametros && !forcePost ? "update" : "create"
        }/`,
        data: values
          ? {
              ...values,
              marca: marcaSelected.id,
              proveedor: idProveedor,
              parametro_id: parametros && !forcePost ? parametros.id : null,
            }
          : {
              ...defaultParametrosValues(parametrosProveedor),
              marca,
              proveedor,
            },
      });
      handleResponsePostParametro(response);
    } catch (e) {
      console.error(e);
      errorNotification("Error al guardar parametros.");
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 150);
  };

  const getMarcasProveedor = async () => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: `/api/marcas/list/proveedor/${idProveedor}/`,
        params: {
          page: params.page,
          q: params.q,
          itemsPerPage: 10,
          includeParametrosInformativos: true,
        },
      });
      handleResponseMarcas(response);
    } catch (e) {
      errorNotification("Error al cargar las marcas.");
    }
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 150);
  };

  const handleResponseMarcas = (response) => {
    if (response.status === 200) {
      setMarcas(response.data.data.items);
      setNumeroItems(response.data.data.num_items);
    } else {
      setMarcas([]);
      setNumeroItems(0);
    }
  };

  const handleResponsePostParametro = (response) => {
    if (response.status === 201) {
      setParametros(null);
      setMarcaSelected(null);
      successNotification("Parametros guardados con exito!");
    } else {
      setParametros(null);
      setMarcaSelected(null);
      errorNotification("Error al guardar parametros.");
    }
    getMarcasProveedor();
  };

  useEffect(() => {
    getParametros(idProveedor).then((response) =>
      setParametrosProveedor(response),
    );
  }, []);

  useEffect(() => {
    getMarcasProveedor();
  }, [params]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      q: searchDebounce,
      page: 1,
    }));
  }, [searchDebounce]);

  return (
    <Container maxWidth className="main-content-container ">
      <PageHeader
        history={history}
        title="Marcas"
        subtitle="Configuración parametros"
      />
      <Grid container className={classes.root} spacing={2}>
        <Grid item container spacing={2} xs={12}>
          <Box component={Paper} pt={2} px={2} style={{ width: "100%" }}>
            <TextField
              label="Buscar marca por nombre"
              variant="outlined"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={1} />
            <TableMarcas
              marcas={marcas}
              parametrosProveedor={parametrosProveedor}
              handleSubmit={handleSubmit}
              update={getMarcasProveedor}
              setOpenBackdrop={setOpenBackdrop}
              onPageChange={(page) => setParams({ ...params, page })}
              page={params.page}
              numeroItems={numeroItems}
            />
          </Box>
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={openBackdrop} />
    </Container>
  );
};

export default Marcas;
