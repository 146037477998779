import * as Yup from "yup";

export default function validationSchema() {
  return Yup.object().shape({
    coeficiente: Yup.number()
      .required("El coeficiente es requerido.")
      .moreThan(0, "El coeficiente debe ser mayor a 0."),
    nombre: Yup.string()
      .required("El nombre es requerido.")
      .max(255, "El nombre no puede tener más de 255 caracteres"),
  });
}
