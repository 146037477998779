import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Grid, Card, CardContent } from "@material-ui/core";
import request from "../../../requests/request";
import { createProveedor, updateProveedor } from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { useParams } from "react-router-dom";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import { getAllData, getIvas } from "./utils";
import NoResults from "../../../components/ait-reusable/NoResults";
import * as isNumber from "is-number";
import { formDefaultValues } from "./FormDefault";
import FormProveedor from "./FormProveedor";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";

export default function FormularioProveedor(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [proveedor, setProveedor] = useState(formDefaultValues);
  const [arrayObservaciones, setArrayObservaciones] = useState([]);
  const [ivas, setIvas] = useState([]);
  let { idProveedor } = useParams();

  const { configGeneral } = useSelector((state) => state.configGeneral);

  const listasDePreciosValidas = (values) => {
    // TODO: Mover al validate del form
    if (values.parametros.some((i) => i.is_precio_lista && i.is_active)) {
      return (
        values.parametros.filter(
          (i) => i.is_precio_lista && i.es_default && i.is_active,
        ).length === 1
      );
    } else {
      return true;
    }
  };

  const sanitizarId = (values) => {
    values.parametros.map((i) => {
      if (i.id < 0) {
        delete i.id;
      }
    });
  };

  const requestProveedor = async (values) => {
    if (
      values.parametros.filter((i) => i.is_precio_lista && i.is_active).length >
      configGeneral.limite_lista_precios
    ) {
      errorNotification(
        "Se excedió el limite de listas de precios permitidas. El limite es de " +
          configGeneral.limite_lista_precios +
          " lista/s de precio/s.",
      );
      setIsSubmitting(false);
    } else if (!listasDePreciosValidas(values)) {
      errorNotification(
        "Valide que solo una lista de precios fue marcada como principal.",
      );
      setIsSubmitting(false);
    } else {
      values.porcentaje_iva =
        values.porcentaje_iva === "" ? "0.0000" : values.porcentaje_iva;
      values.margen_ganancia =
        values.margen_ganancia === "" ? "0.0000" : values.margen_ganancia;
      values.descuento = values.descuento === "" ? "0.0000" : values.descuento;
      values.recargo_lista =
        values.recargo_lista === "" ? "0.0000" : values.recargo_lista;
      values.recargo_contado =
        values.recargo_contado === "" ? "0.0000" : values.recargo_contado;

      sanitizarId(values);

      const dataProveedor = idProveedor
        ? {
            ...values,
            identificador: values.identificador ? values.identificador : null,
            activo: true,
            aplicaba_retencion: values.retencionActiva,
            baja_certificado:
              values.retencionActiva && !values.calcular_retencion
                ? {
                    motivo: values.bajaCertificado.motivo_baja.id,
                    responsable: values.bajaCertificado.responsable_baja
                      ? values.bajaCertificado.responsable_baja.idEmpleado
                      : null,
                  }
                : null,
            certificado:
              values.retencionActiva &&
              !values.calcular_retencion &&
              values.bajaCertificado.motivo_baja.nombre ===
                "Tengo certificado de exclusión"
                ? {
                    nro_certificado: values.certificado.nroCertificado || null,
                    fecha_inicio: values.certificado.fechaInicio || null,
                    fecha_fin: values.certificado.fechaFin || null,
                  }
                : null,
            nuevos_certificados:
              values.nuevos_certificados.length > 0
                ? values.nuevos_certificados
                : null,
            parametros: values.parametros.map((p) => ({
              ...p,
              descuentos_recargos_informativos:
                p.descuentos_recargos_informativos.map((d) =>
                  d.nuevo
                    ? {
                        ...d,
                        id: null,
                      }
                    : d,
                ),
            })),
          }
        : {
            ...values,
            identificador: values.identificador ? values.identificador : null,
            activo: true,
            observaciones: arrayObservaciones,
          };

      setIsSubmitting(true);
      try {
        const response = await request({
          method: (idProveedor && "PUT") || "POST",
          url: (idProveedor && updateProveedor(idProveedor)) || createProveedor,
          data: dataProveedor,
        });
        handleResponseProveedor(response);
      } catch (error) {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error ===
            "El proveedor tiene pagos con retenciones aplicadas"
        ) {
          errorNotification(
            "El proveedor tiene pagos con retenciones aplicadas. No se puede desactivar el cálculo de retención.",
          );
        } else {
          errorNotification(
            "Ocurrió un error durante el registro. Verifique la información.",
          );
        }
        setIsSubmitting(false);
      }
    }
  };

  const handleResponseProveedor = (response) => {
    if (response.status === 201) {
      !idProveedor && setProveedor(formDefaultValues);
      successNotification(
        idProveedor
          ? `Proveedor modificado exitosamente`
          : `Proveedor registrado exitosamente`,
      );
      setTimeout(() => {
        props.history.push("/proveedores/ver-proveedores/");
      }, 2000);
    } else {
      errorNotification(
        "Ocurrió un error durante el registro. Verifique la información.",
      );
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (idProveedor && isNumber(idProveedor)) {
      getAllData(idProveedor).then((response) => {
        if (Object.values(response).length !== 0) {
          setProveedor({
            ...response.proveedor.data.data,
            retencionActiva: response.proveedor.data.data.calcular_retencion,
            bajaCertificado: {
              motivo_baja: null,
              responsable_baja: null,
            },
            certificado: {
              nroCertificado: "",
              fechaInicio: "",
              fechaFin: "",
            },
            nuevos_certificados: [],
            parametros: response.proveedor.data.data.parametros.map((p) => ({
              ...p,
              descuentos_recargos_informativos:
                p.descuentos_recargos_informativos.map((d) => ({
                  ...d,
                  nuevo: false,
                })),
            })),
          });
        }
      });
    }
    getIvas().then((response) => {
      setIvas(response.data);
    });
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"PROVEEDOR"}
        subtitle={idProveedor ? "Modificar PROVEEDOR" : "Registrar PROVEEDOR"}
        history={props.history}
        onChangeInputs
      />
      <Grid container spacing={2} className={"d-flex justify-content-center"}>
        <Grid item>
          <Card>
            <CardContent>
              {!idProveedor ||
              (idProveedor && Object.values(proveedor).length !== 0) ? (
                <FormProveedor
                  proveedor={proveedor}
                  isEdicion={!!idProveedor}
                  ivas={ivas}
                  isSubmittingForm={isSubmitting}
                  handleSubmit={(values) => requestProveedor(values)}
                  arrayObservaciones={arrayObservaciones}
                  setArrayObservaciones={setArrayObservaciones}
                />
              ) : (
                <NoResults />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={isSubmitting} />
    </Container>
  );
}
