import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import ButtonCancelar from "../../../../components/ait-reusable/Button/ButtonCancelar";
import DescuentoYRecargoButton from "../../../../components/ait-reusable/DescuentoYRecargoButton";
import { TIPO_COEFICIENTE } from "../../../../components/ait-reusable/ModalDescuentoYRecargo/utils";
import { Alert } from "@material-ui/lab";
import ReusableTable from "../../../../components/ait-reusable/Report/ReusableTable";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import { useParams } from "react-router-dom";
import request from "../../../../requests/request";
import Spinner from "../../../../components/Spinner";
import TablaDescuentosYRecargos from "../../../../components/ait-reusable/TablaDescuentosYRecargos";
import { useFormik } from "formik";
import { defaultParametrosValues, proveedorUseListaPrecio } from "../../utils";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";
import {
  useCreateParametroDePrecios,
  useUpdateParametroDePrecios,
} from "../../../../services/marca";

export default function ModalParametrosPreciosMarca({
  marca,
  open,
  close,
  parametrosProveedor,
  updateListaMarcas,
}) {
  let { idProveedor } = useParams();
  const [isLoading, setLoading] = React.useState(true);
  const [descuentos_recargos_informativos, setDescuentosRecargosInformativos] =
    React.useState(
      marca.descuentos_recargos_informativos.map((coef) => ({
        ...coef,
        nuevo: false,
      })),
    );
  const formik = useFormik({
    initialValues: {
      porcentaje_descuento: "0.00000",
      porcentaje_contado: "0.00000",
      porcentaje_lista: "0.00000",
    },
    onSubmit: async (values) => {
      let data = {
        ...values,
        descuentos_recargos_informativos: descuentos_recargos_informativos.map(
          (coef) => (coef.nuevo ? { ...coef, id: null } : coef),
        ),
      };
      if (marca.has_parametro) {
        await updateParametrosDePrecios.mutateAsync({
          parametro_id: marca.parametros_precio.id,
          proveedor: idProveedor,
          marca: marca.id,
          ...data,
        });
      } else {
        await createParametrosDePrecios.mutateAsync({
          marca: marca.id,
          proveedor: idProveedor,
          ...data,
        });
      }
    },
  });
  const createParametrosDePrecios = useCreateParametroDePrecios({
    queryProps: {
      onSuccess: () => {
        updateListaMarcas();
        close();
        successNotification("Parametros de precios guardados correctamente.");
      },
      onError: () => {
        errorNotification("Error al guardar los parametros de precios.");
      },
    },
  });
  const updateParametrosDePrecios = useUpdateParametroDePrecios({
    queryProps: {
      onSuccess: () => {
        updateListaMarcas();
        close();
        successNotification(
          "Parametros de precios actualizados correctamente.",
        );
      },
      onError: () => {
        errorNotification("Error al actualizar los parametros de precios.");
      },
    },
  });

  React.useEffect(() => {
    getParametrosMarca();
  }, []);

  const getParametrosMarca = async () => {
    try {
      const response = await request({
        method: "GET",
        url: `/api/marcas/parametros/get/`,
        params: { marca: marca.id, proveedor: idProveedor },
      });
      formik.setValues(
        defaultParametrosValues(response.data && response.data.data),
      );

      setLoading(false);
    } catch (error) {
      errorNotification("Error al obtener los parametros de la marca");
      close();
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          Parámetros de {marca.nombre}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 20 }}>
        {isLoading && (
          <Box display="flex" justifyContent="center" p={2}>
            <Spinner color="#66b3ff" type="spin" />
          </Box>
        )}

        {!isLoading && (
          <Box display={"flex"} style={{ gap: 8 }}>
            <Box style={{ width: "100%" }}>
              <Box display="flex" justifyContent="center" style={{ gap: 2 }}>
                <TextField
                  id="porcentaje_descuento"
                  name="porcentaje_descuento"
                  label="% Descuento"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  variant={"outlined"}
                  disabled={!proveedorUseListaPrecio(parametrosProveedor)}
                  value={formik.values.porcentaje_descuento}
                />

                <DescuentoYRecargoButton
                  asIcon
                  disabled={!proveedorUseListaPrecio(parametrosProveedor)}
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO}
                  marca={marca}
                  coeficienteConfigurado={formik.values.porcentaje_descuento}
                  onChangeCoeficiente={(updatedItems) =>
                    setDescuentosRecargosInformativos(updatedItems)
                  }
                  coeficientesCargados={descuentos_recargos_informativos}
                />
              </Box>
              <Box mt={1} />
              <TablaDescuentosYRecargos
                showAlert
                coeficientePrincipal={formik.values.porcentaje_descuento}
                coeficientesCargados={descuentos_recargos_informativos.filter(
                  (coef) =>
                    coef.tipo_coeficiente ===
                    TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO,
                )}
                tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO}
                disabledAplicar={!proveedorUseListaPrecio(parametrosProveedor)}
                onAplicar={async (value) =>
                  await formik.setFieldValue("porcentaje_descuento", value)
                }
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box style={{ width: "100%" }}>
              <Box display="flex" justifyContent="center" style={{ gap: 2 }}>
                <TextField
                  id="porcentaje_contado"
                  name="porcentaje_contado"
                  label="% Contado"
                  fullWidth
                  variant={"outlined"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.porcentaje_contado}
                />
                <DescuentoYRecargoButton
                  asIcon
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_CONTADO}
                  marca={marca}
                  coeficienteConfigurado={formik.values.porcentaje_contado}
                  onChangeCoeficiente={(updatedItems) =>
                    setDescuentosRecargosInformativos(updatedItems)
                  }
                  coeficientesCargados={descuentos_recargos_informativos}
                />
              </Box>
              <Box mt={1} />
              <TablaDescuentosYRecargos
                showAlert
                coeficientePrincipal={formik.values.porcentaje_contado}
                coeficientesCargados={descuentos_recargos_informativos.filter(
                  (coef) =>
                    coef.tipo_coeficiente ===
                    TIPO_COEFICIENTE.PORCENTAJE_CONTADO,
                )}
                tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_CONTADO}
                onAplicar={async (value) =>
                  await formik.setFieldValue("porcentaje_contado", value)
                }
              />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box style={{ width: "100%" }}>
              <Box display="flex" justifyContent="center" style={{ gap: 2 }}>
                <TextField
                  id="porcentaje_lista"
                  name="porcentaje_lista"
                  label="% Lista"
                  variant={"outlined"}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={proveedorUseListaPrecio(parametrosProveedor)}
                  value={formik.values.porcentaje_lista}
                />
                <DescuentoYRecargoButton
                  asIcon
                  disabled={proveedorUseListaPrecio(parametrosProveedor)}
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_LISTA}
                  marca={marca}
                  coeficienteConfigurado={formik.values.porcentaje_lista}
                  onChangeCoeficiente={(updatedItems) =>
                    setDescuentosRecargosInformativos(updatedItems)
                  }
                  coeficientesCargados={descuentos_recargos_informativos}
                />
              </Box>
              <Box mt={1} />
              <TablaDescuentosYRecargos
                showAlert
                coeficientePrincipal={formik.values.porcentaje_lista}
                coeficientesCargados={descuentos_recargos_informativos.filter(
                  (coef) =>
                    coef.tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_LISTA,
                )}
                tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_LISTA}
                onAplicar={async (value) =>
                  await formik.setFieldValue("porcentaje_lista", value)
                }
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <ButtonCancelar message={"Salir"} click={close} />
        <ButtonAceptar
          message={"Guardar"}
          click={() => formik.handleSubmit()}
          loading={
            createParametrosDePrecios.isLoading ||
            updateParametrosDePrecios.isLoading
          }
        />
      </DialogActions>
    </Dialog>
  );
}
