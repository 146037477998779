import { TIPO_COEFICIENTE } from "../ModalDescuentoYRecargo/utils";

export function formatDescuentosYRecargo({ coeficientes }) {
  return coeficientes.map((coeficiente) => {
    return {
      nombre: coeficiente.nombre,
      coeficiente: `${Number(coeficiente.coeficiente).toFixed(2)}%`,
    };
  });
}

export const calcularTotalPorcentajeCargado = ({
  coeficientesCargadosActivos,
  tipo_coeficiente,
}) => {
  if (tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO) {
    // Monto de referencia, es 100 para poder calcular el porcentaje
    let montoReferencia = 100;

    // Monto base sobre el cual se aplican los descuentos
    let montoBaseInicial = 100;

    for (let coeficiente of coeficientesCargadosActivos) {
      montoBaseInicial =
        montoBaseInicial -
        montoBaseInicial * (Number(coeficiente.coeficiente) / 100);
    }

    // Diferencia entre el monto de referencia y el monto base
    let diferencia = montoReferencia - montoBaseInicial;

    // Porcentaje de diferencia
    let porcentajeDiferencia = (diferencia / montoReferencia) * 100;

    return Number(porcentajeDiferencia.toFixed(2));
  } else {
    // Monto de referencia, es 100 para poder calcular el porcentaje
    let montoReferencia = 100;

    // Monto base sobre el cual se aplican los descuentos
    let montoBaseInicial = 100;

    for (let coeficiente of coeficientesCargadosActivos) {
      montoBaseInicial =
        montoBaseInicial +
        montoBaseInicial * (Number(coeficiente.coeficiente) / 100);
    }

    // Diferencia entre el monto de referencia y el monto base
    let diferencia = montoBaseInicial - montoReferencia;

    // Porcentaje de diferencia
    let porcentajeDiferencia = (diferencia / montoReferencia) * 100;

    return Number(porcentajeDiferencia.toFixed(2));
  }
};
