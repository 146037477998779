import React from "react";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ModalParametrosPreciosMarca from "../../../ModalParametrosPreciosMarca";
import useDisclosure from "../../../../../../customHooks/useDisclosure";

export default function EditarParametroMarca({
  marca,
  parametrosProveedor,
  updateListaMarcas,
}) {
  const [opened, { open, close }] = useDisclosure();
  return (
    <>
      <IconButton size="small" style={{ color: "black" }} onClick={open}>
        <EditIcon color="inherit" />
      </IconButton>

      {opened && (
        <ModalParametrosPreciosMarca
          marca={marca}
          open={opened}
          close={close}
          parametrosProveedor={parametrosProveedor}
          updateListaMarcas={updateListaMarcas}
        />
      )}
    </>
  );
}
