import React from "react";
import { useHistory, useParams } from "react-router";
import { Tabs, Tab, Divider, Box, Container } from "@material-ui/core";
import Proveedores from "./index";
import Pedidos from "./Pedidos";
import AjustePrecioCosto from "./AjustePrecioCosto/IndexAjuste";
import ListaCargaListas from "./listasManuales/ListaCargaListas";
import PageTitle from "../../components/common/PageTitle";
import { useSelector } from "react-redux";
import ArticulosRepetidos from "./ArticulosRepetidos";
import { formDefaultListaDePrecios } from "./NuevoFormProveedor/FormDefault";

export default function ConfigurationPadreTabs() {
  const { tab } = useParams();

  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  const tabs = () => {
    let array = [];
    if (isVendedor) {
      array = [
        { value: "ver-proveedores", label: "Proveedores" },
        { value: "pedidos", label: "Pedidos" },
        { value: "articulos-duplicados", label: "Artículos duplicados" },
      ];
    } else {
      array = [
        { value: "ver-proveedores", label: "Proveedores" },
        { value: "pedidos", label: "Pedidos" },
        { value: "ajuste-precio-costo", label: "Ajuste de precios" },
        { value: "listas-manuales", label: "Listas Manuales" },
        { value: "articulos-duplicados", label: "Artículos duplicados" },
      ];
    }
    return array;
  };

  const history = useHistory();

  const handleOnChangeTab = (event, value) => {
    history.push(`/proveedores/${value}`);
  };

  const subtitlePage = () => {
    switch (tab) {
      case "ver-proveedores":
        return "Listado de proveedores";

      case "pedidos":
        return "Cargar pedido";

      case "ajuste-precio-costo":
        return "Artículos";

      case "listas-manuales":
        return "Listas manuales";

      default:
        return "";
    }
  };
  const titlePage = () => {
    switch (tab) {
      case "ver-proveedores":
        return "Proveedores";

      case "pedidos":
        return "Pedido";

      case "ajuste-precio-costo":
        return "Ajuste de precio";

      case "listas-manuales":
        return "Listas";

      case "articulos-duplicados":
        return "Artículos duplicados";

      default:
        return "";
    }
  };

  return (
    <>
      <Container maxWidth className="main-content-container px-4 pt-2">
        <PageTitle
          sm={4}
          title={titlePage()}
          subtitle={subtitlePage()}
          className="text-sm-left"
        />
        <Tabs
          indicatorColor="primary"
          value={tab}
          textColor="primary"
          onChange={handleOnChangeTab}
          aria-label="simple tabs example"
        >
          {tabs().map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Box pb={1}>
          <Divider />
        </Box>
        {tab === "ver-proveedores" && <Proveedores history={history} />}
        {tab === "pedidos" && <Pedidos />}
        {tab === "ajuste-precio-costo" && <AjustePrecioCosto />}
        {tab === "articulos-duplicados" && <ArticulosRepetidos />}
        {tab === "listas-manuales" && <ListaCargaListas />}
      </Container>
    </>
  );
}
