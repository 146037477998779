import Alert from "@material-ui/lab/Alert";
import React from "react";
import { calcularTotalPorcentajeCargado } from "../../TablaDescuentosYRecargos/utils";

export default function AlertWarningCoeficienteConfigurado({
  getDescuentoYRecargo,
  coeficienteConfigurado,
  tipo_coeficiente,
}) {
  const totalCoeficientesInformativos = calcularTotalPorcentajeCargado({
    coeficientesCargadosActivos: getDescuentoYRecargo.data,
    tipo_coeficiente,
  });

  if (coeficienteConfigurado == totalCoeficientesInformativos) {
    return null;
  }

  return (
    <Alert severity="warning">
      La sumatoria de los coeficientes cargados difiere del coeficiente
      configurado. Los coeficientes cargados son solo informativos y no
      modifican la configuración principal.
      <br />
      <span style={{ fontWeight: "bolder" }}>Coeficiente configurado: </span>
      {Number(coeficienteConfigurado)}
      <br />
      <span style={{ fontWeight: "bolder" }}>
        Suma de coeficientes cargados:{" "}
      </span>
      {totalCoeficientesInformativos}
    </Alert>
  );
}
