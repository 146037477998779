import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Divider, Switch } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import EditarParametroMarca from "./componentes/EditarParametroMarca";
import { TIPO_COEFICIENTE } from "../../../../../components/ait-reusable/ModalDescuentoYRecargo/utils";
import TablaDescuentosYRecargos from "../../../../../components/ait-reusable/TablaDescuentosYRecargos";
import { toggleEnabledParametros } from "../../../utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      // borderBottom: "unset",
    },
  },
});

export default function TableMarcaRow({
  marca,
  parametrosProveedor,
  handleSubmit,
  update,
  setOpenBackdrop,
}) {
  let { idProveedor } = useParams();
  // const [marca, setMarca] = React.useState(marcaData);
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{marca.nombre}</TableCell>
        <TableCell align="center">
          {marca.has_parametro
            ? Number(marca.parametros_precio.porcentaje_descuento).toFixed(2)
            : "---"}
        </TableCell>
        <TableCell align="center">
          {marca.has_parametro
            ? Number(marca.parametros_precio.porcentaje_contado).toFixed(2)
            : "---"}
        </TableCell>
        <TableCell align="center">
          {marca.has_parametro
            ? Number(marca.parametros_precio.porcentaje_lista).toFixed(2)
            : "---"}
        </TableCell>
        <TableCell align="center">
          <Switch
            checked={marca.has_parametro && marca.is_enabled}
            onChange={() => {
              marca.has_parametro
                ? toggleEnabledParametros(
                    marca.id,
                    idProveedor,
                    update,
                    setOpenBackdrop,
                  )
                : handleSubmit(null, marca.id, idProveedor, true);
            }}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell align="center">
          <EditarParametroMarca
            marca={marca}
            parametrosProveedor={parametrosProveedor}
            updateListaMarcas={update}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display={"flex"} style={{ gap: 16 }}>
              <Box width={"100%"}>
                <Box mt={1} />
                <Typography variant="body1" align="center" gutterBottom>
                  Descuentos
                </Typography>
                <TablaDescuentosYRecargos
                  coeficientePrincipal={0}
                  coeficientesCargados={marca.descuentos_recargos_informativos.filter(
                    (coef) =>
                      coef.tipo_coeficiente ===
                      TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO,
                  )}
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO}
                />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box width={"100%"}>
                <Box mt={1} />
                <Typography variant="body1" align="center" gutterBottom>
                  Recargo contado
                </Typography>
                <TablaDescuentosYRecargos
                  coeficientePrincipal={0}
                  coeficientesCargados={marca.descuentos_recargos_informativos.filter(
                    (coef) =>
                      coef.tipo_coeficiente ===
                      TIPO_COEFICIENTE.PORCENTAJE_CONTADO,
                  )}
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_CONTADO}
                />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box width={"100%"}>
                <Box mt={1} />
                <Typography variant="body1" align="center" gutterBottom>
                  Recargo lista
                </Typography>
                <TablaDescuentosYRecargos
                  coeficientePrincipal={0}
                  coeficientesCargados={marca.descuentos_recargos_informativos.filter(
                    (coef) =>
                      coef.tipo_coeficiente ===
                      TIPO_COEFICIENTE.PORCENTAJE_LISTA,
                  )}
                  tipo_coeficiente={TIPO_COEFICIENTE.PORCENTAJE_LISTA}
                />
                <Box />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
