import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { getTokenHeader } from "../../../../utils/HeaderToken";

const boxerAfipServices = {
  buscarFacturaEnAfip: async ({ pos, rtype, rnum }) => {
    const res = await axios.get(
      `/api/sync-afip/fetch-receipt/?pos=${pos}&rtype=${rtype}&rnum=${rnum}`,
      {
        headers: { ...getTokenHeader() },
      },
    );
    return res.data;
  },
  buscarFacturasInexistentesBoxer: async () => {
    const res = await axios.get("/api/sync-afip/missing-receipts/", {
      headers: { ...getTokenHeader() },
    });
    return res.data.receipts;
  },
  vincularFacturaConVenta: async ({ venta, pos, nro_factura, cae, fecha }) => {
    const res = await axios.post(
      `/api/sync-afip/duplicate-venta/?venta=${venta}&pos=${pos}&nro_factura=${nro_factura}&cae=${cae}&fecha=${fecha}`,
      {},
      {
        headers: { ...getTokenHeader() },
      },
    );
    return res.data;
  },
};

export const useBuscarFacturaEnAfip = ({ params, queryConfig = {} }) =>
  useQuery(
    ["buscarFacturaEnAfip", params],
    () => boxerAfipServices.buscarFacturaEnAfip(params),
    { enabled: !!params, cacheTime: 0, ...queryConfig },
  );

export const useBuscarFacturasInexistentesBoxer = (queryConfig = {}) =>
  useQuery(
    ["buscarFacturasInexistentesBoxer"],
    boxerAfipServices.buscarFacturasInexistentesBoxer,
    { initialData: [], ...queryConfig },
  );

export const useVincularFacturaConVenta = (queryConfig = {}) =>
  useMutation(
    "vincularFacturaConVenta",
    boxerAfipServices.vincularFacturaConVenta,
    { ...queryConfig },
  );
