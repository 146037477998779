import { Typography } from "@material-ui/core";
import React from "react";

export default function DetalleDescuentosYRecargos({ descuentosYRecargos }) {
  if (!descuentosYRecargos) {
    return "---";
  }

  return descuentosYRecargos.map((item, index) => {
    const debeMostrarPrecios = Boolean(item.precios_base);

    return (
      <>
        <Typography key={index} variant="body2">
          {`${item.nombre} de ${item.origen}: ${Number(item.porcentaje).toFixed(2)}%`}
        </Typography>
        {debeMostrarPrecios && (
          <>
            <Typography variant="caption">
              Lista precio sin IVA:{" "}
              {parseFloat(item.precios_base.lista).toLocaleString("es-AR")}
            </Typography>
            <br />
          </>
        )}
        {item.descuentos_recargos_informativos.map(
          (descuento_recargo, index) => (
            <>
              <Typography key={index} variant="caption">
                {debeMostrarPrecios
                  ? `- ${descuento_recargo.nombre}: ${parseFloat(descuento_recargo.coeficiente).toFixed(2)}% - ${parseFloat(descuento_recargo.descuentos.descuento_lista).toLocaleString("es-AR")}`
                  : `- ${descuento_recargo.nombre}: ${parseFloat(descuento_recargo.coeficiente).toFixed(2)}%`}
              </Typography>
              <br />
            </>
          ),
        )}
        <br />
      </>
    );
  });
}
