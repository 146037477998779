import React from "react";
import ReusableTable from "../../Report/ReusableTable";
import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useDisclosure from "../../../../customHooks/useDisclosure";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";

const DarBajaCoeficiente = ({ item, bajaDescuentoYRecargo }) => {
  const [opened, { open, close }] = useDisclosure();

  // () => bajaDescuentoYRecargo.mutate(item.id)
  return (
    <>
      <IconButton size="small" onClick={open}>
        <DeleteIcon fontSize="small" color="error" />
      </IconButton>
      {opened && (
        <ModalConfirm
          open={opened}
          title={"¿Está seguro de dar de baja el coeficiente?"}
          body={
            <Typography>
              {`Si acepta, el coeficiente ${item.nombre} de ${Number(item.coeficiente).toFixed(2)} % será dado de baja.`}
            </Typography>
          }
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
          handleSubmit={async () => {
            await bajaDescuentoYRecargo.mutateAsync(item.id);
            close();
          }}
          handleClose={close}
          openBackdrop={
            bajaDescuentoYRecargo.isLoading || bajaDescuentoYRecargo.isFetching
          }
        />
      )}
    </>
  );
};

const formatData = ({ items, bajaDescuentoYRecargo }) =>
  items.map((item) => ({
    nombre: item.nombre,
    coeficiente: `${Number(item.coeficiente).toFixed(2)} %`,
    actions: (
      <DarBajaCoeficiente
        item={item}
        bajaDescuentoYRecargo={bajaDescuentoYRecargo}
      />
    ),
  }));

export default function TablaCoeficiente({
  getDescuentoYRecargo,
  bajaDescuentoYRecargo,
}) {
  const COLUMNS = ["Nombre", "Coeficiente", "---"];
  return (
    <Box>
      <ReusableTable
        columns={COLUMNS}
        items={formatData({
          items: getDescuentoYRecargo.data,
          bajaDescuentoYRecargo,
        })}
        loading={
          getDescuentoYRecargo.isLoading ||
          getDescuentoYRecargo.isFetching ||
          bajaDescuentoYRecargo.isLoading ||
          bajaDescuentoYRecargo.isFetching
        }
      />
    </Box>
  );
}
