import React, { useState } from "react";
import { Container } from "@material-ui/core";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import FormArticulo from "./formArticulo";
import {
  initialStateArticulo,
  initialStateUbicacion,
  LabelUbicaciones,
  getUbicaciones,
  StateEditArticulo,
  ArrayArticulo,
} from "./utils";
import ModalUbicacion from "./ModalUbicacion";
import {
  postArticulo,
  getArticuloPorID,
  putArticulo,
  getProveedorSucursal,
} from "../../../requests/urls";
import { useParams } from "react-router-dom";
import { peticiones } from "../../../utils/peticiones";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import * as isNumber from "is-number";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";

export default function index(props) {
  const [articulo, setarticulo] = useState(initialStateArticulo());
  const [isOk, setisOk] = useState(false);
  const [edit, setedit] = useState(false);
  const [addUbicacion, setAddUbicacion] = useState(false);
  const [ubicaciones, setubicaciones] = useState([]);
  const [modal, setModal] = useState(false);
  const [ubicacionArticulo, setubicacionArticulo] = useState([]);
  const [ubicacion, setUbicacion] = useState(initialStateUbicacion());
  const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false);
  const [loading, setLoading] = useState(false);

  let { idArticulo } = useParams();

  const getArticulo = async () => {
    /**Para cuando hay que pedir datos de back */
    await peticiones("get", getArticuloPorID(idArticulo)).then((res) => {
      if (res.data.status === 200) {
        let articulo = res.data.data;
        setarticulo(StateEditArticulo(articulo));
        setedit(true);
        if (articulo.idUbicacion !== null) {
          setubicacionArticulo([
            {
              idDeposito: articulo.deposito,
              idZona: articulo.zona,
              idEstante: articulo.estante,
              idUbicacion: articulo.fila,
            },
          ]);
          setAddUbicacion(true);
        }
      }
    });
  };

  const handleSubmit = async (
    values,
    manejaStock,
    obsArticulo,
    marcaSelected,
    reloadPage = false,
  ) => {
    try {
      setLoading(true);
      let data = ArrayArticulo(
        values,
        manejaStock,
        ubicacionArticulo,
        obsArticulo,
        marcaSelected,
        addUbicacion,
      );

      let res = await peticiones(
        edit ? "put" : "post",
        edit ? putArticulo(values.idArticulo) : postArticulo,
        data,
      );
      setisOk(true);
      successNotification(
        edit
          ? "Se modificaron los datos correctamente"
          : "El artículo se agregó correctamente",
      );

      if (reloadPage && !edit) {
        props.history.replace(
          `/articulos/modificar-articulo/${res.data.data.idRepuestoSucursal}`,
        );
        setisOk(false);
        setLoading(false);
        return;
      }

      if (reloadPage && edit) {
        await getArticulo();
        setisOk(false);
        setLoading(false);
        return;
      }

      props.history.goBack();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setLoading(false);
        errorNotification(error.response.data.non_field_errors);
        return;
      }

      setLoading(false);
      errorNotification(
        edit
          ? "No se pudo guardar los cambios, intente nuevamente"
          : "No se pudo registrar el árticulo, intente nuevamente",
      );
    }
  };

  const getParamProveedor = (idProveedor, setFieldValue) => {
    idProveedor !== ""
      ? peticiones("get", getProveedorSucursal(idProveedor)).then((res) => {
          if (res.data.status === 200) {
            setFieldValue("is_precio_lista", res.data.data.is_precio_lista);
          }
        })
      : setFieldValue("is_precio_lista", false);
  };

  React.useEffect(() => {
    if (idArticulo && isNumber(idArticulo)) getArticulo();
    getUbicaciones("").then((res) => setubicaciones(res));
  }, []);

  const handleUbicacionArticulo = async (data, index) => {
    setModal(false);
    setubicacionArticulo([data]);
  };

  const handlemodal = (value) => {
    setAddUbicacion(!value);
    setModal(!value ? true : false);
  };

  const deleteUbicacion = () => {
    setAddUbicacion(!addUbicacion);
    setubicacionArticulo([]);
    setOpenModalConfirmacion(false);
  };

  const openModalConfirm = () => {
    setOpenModalConfirmacion(true);
  };

  return (
    <Container maxWidth={false} className="main-content-container px-4">
      <PageHeader
        title={"ARTÍCULOS"}
        subtitle={edit ? "Modificar Artículo" : "Registrar Artículo"}
        onChangeInputs
        history={props.history}
      />
      <FormArticulo
        articulo={articulo}
        edit={edit}
        ubicacion={ubicacion}
        modal={handlemodal}
        handleSubmit={handleSubmit}
        isOk={isOk}
        setArticulo={setarticulo}
        getParamProveedor={getParamProveedor}
        getArticulo={getArticulo}
        loadingForm={loading}
        addUbicacion={addUbicacion}
        setAddUbicacion={setAddUbicacion}
      />
      {modal ? (
        <ModalUbicacion
          open={modal}
          toggle={() => setModal(!modal)}
          deposito={ubicaciones}
          handleSubmitUbicacion={handleUbicacionArticulo}
          dataUbicacionArticulo={ubicacionArticulo}
        />
      ) : null}
      {openModalConfirmacion && (
        <ModalConfirm
          open={openModalConfirmacion}
          handleClose={() => setOpenModalConfirmacion(false)}
          body={
            <>
              ¿Está seguro que desea eliminar la ubicación?
              <br />
              <small>Recuerde guardar los cambios.</small>
            </>
          }
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
          handleSubmit={() => deleteUbicacion()}
        />
      )}
      {ubicacionArticulo.length > 0 && addUbicacion ? (
        <LabelUbicaciones
          ubicacionArticulo={ubicacionArticulo}
          onClick={() => setModal(!modal)}
          deleteUbicacion={deleteUbicacion}
          openModalConfirm={openModalConfirm}
        />
      ) : null}
      <CircularBackdrop openBackdrop={loading} />
    </Container>
  );
}
