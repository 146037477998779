import moment from "moment";
import { getMontosDeIVA } from "./Hooks/utils";

export const formatDataPago = (
  selection,
  useTotales,
  useComprasNC,
  idProveedor,
  dataGral,
  empleado,
  medios,
) => {
  let { idCompras, idNC } = getIdComprasNC(selection);
  const dataMontos = getDataMontos(useComprasNC);
  const tienePagosPorPlazo = useComprasNC.newCompras.some((c) => c.isPlazoPago);

  let data = {
    compras_id: idCompras,
    notas_credito_id: idNC,
    monto_pago: Number(Number(useTotales.montoAPagarConRetencion).toFixed(2)),
    proveedor: idProveedor,
    descuento:
      dataGral.descuento === "" || dataGral.descuento === 0
        ? 0
        : Number((Number(dataGral.descuento) / 100).toFixed(7)),
    monto_neto:
      dataGral.descuento === "" || dataGral.descuento === 0
        ? null
        : getMontoNeto(useComprasNC),
    responsable: empleado.idEmpleado,
    observacion: dataGral.observacion,
    // Parche 07 febrero 2025
    // Si el pago tiene pagos por plazo, se envía false,
    // sino se envía el valor de isParcial
    es_parcial: tienePagosPorPlazo
      ? true
      : getIsParcial(useComprasNC, dataGral),
    fecha_real_pago: moment(dataGral.fechaReal).format("YYYY-MM-DD HH:mm"),
    compra_data: dataCompras(useComprasNC, useTotales),
    proporcion_nota_credito: dataNC(useComprasNC),
    proporcion_pagocompra: dataPropPagoCompra(useComprasNC),
    //Estos montos son los montos finales del excel. Tengan a no retencion se mandan igual
    monto_base_pago: Number(Number(dataMontos.monto_base_pago).toFixed(2)),
    monto_iva_21_pago: Number(Number(dataMontos.monto_iva_21_pago).toFixed(2)),
    monto_iva_10_pago: Number(Number(dataMontos.monto_iva_10_pago).toFixed(2)),
    monto_percepciones_pago: Number(
      Number(dataMontos.monto_percepciones_pago).toFixed(2),
    ),
    sobrante: Number(Number(useTotales.sobrante).toFixed(2)),
    retencion: getInfoTieneRetencion(
      useTotales.useRetencion.tieneRetencion,
      useTotales.useRetencion.data,
    )
      ? [
          {
            monto_retencion: Number(
              Number(useTotales.useRetencion.data.montoARetener).toFixed(2),
            ),
            observacion: useTotales.useRetencion.data.observacion,
          },
        ]
      : null,
    medio_pago: getInfoMediosPago(medios),
  };

  return data;
};

const getIdComprasNC = (selection) => {
  let idCompras = [];
  let idNC = [];

  selection.forEach((s) => {
    if (s.tipo_comprobante.nombre !== "Notas de Crédito") {
      // Se agrega esto para que solo agregue el id de un solo plazo ya que tienen la misma compra
      if (!idCompras.includes(s.id)) {
        idCompras.push(s.id);
      }
    } else {
      idNC.push(s.id);
    }
  });

  return { idCompras, idNC };
};

//Obtiene la sumatoria de los montos totales menos los totales de nc de cada compra
const getMontoNeto = (useComprasNC) => {
  let resultado = useComprasNC.newCompras.reduce((total, item) => {
    let mNeto = item.isPlazoPago
      ? Number(Number(item.montoAPagar).toFixed(2))
      : Number(
          (
            Number(item.deuda_restante) - Number(item.montosARestarNC.total)
          ).toFixed(2),
        );

    return total + mNeto;
  }, 0);

  return Number(resultado.toFixed(2));
};

const dataCompras = (useComprasNC, useTotales) => {
  const { tieneRetencion, data } = useTotales.useRetencion;
  const valueTieneRetencion = getInfoTieneRetencion(tieneRetencion, data);
  let newData = [];

  useComprasNC.newCompras.forEach((item) => {
    newData.push({
      id: item.compra,
      monto_pagado: Number(Number(item.montoAPagar).toFixed(2)),
      saldo_compra: Number(Number(item.deuda_restante).toFixed(2)),
      porcentaje_pago: Number(Number(item.porcPago).toFixed(2)),
      monto_neto_compra: valueTieneRetencion
        ? Number(
            (
              Number(item.newMontos.neto) *
              (Number(item.porcPago) / 100)
            ).toFixed(2),
          )
        : null, //Es el monto del neto de la compra. Si tiene retencion se manda, sino null
      id_pagos_compras: item.idPlazosCompra,
    });
  });

  return newData;
};

//Funcion que se utiliza para saber si se esta aplicando retencion o no
const getInfoTieneRetencion = (tieneRetencion, data) => {
  let value = false;

  if (tieneRetencion) {
    //Significa que tiene retencion
    if (data.isRetencionMinima) {
      //Significa que la retencion es minima
      if (data.aplicarRetencion) {
        //Significa que tiene retencion minima pero que la esta aplicando
        value = true;
      }
    } else {
      //Significa que la retencion no es minima por ende aplica retencion
      value = true;
    }
  }

  return value;
};

const dataNC = (useComprasNC) => {
  let data = [];
  let cantNC =
    useComprasNC.dataComprobantes.montos_netos_compras.notas_credito.length;

  useComprasNC.newCompras.forEach((compra) => {
    useComprasNC.dataComprobantes.montos_netos_compras.notas_credito.forEach(
      (nc) => {
        const { montoIVA21, montoIVA105 } = getMontosDeIVA(nc);
        let netoNC =
          Number(Number(nc.monto_neto).toFixed(2)) +
          Number((compra.infoAdicionalNC.montoSumarANeto / cantNC).toFixed(2));
        let iva21NC = Number(Number(montoIVA21).toFixed(2));
        let iva105NC = Number(Number(montoIVA105).toFixed(2));
        let percepNC = Number(Number(nc.percepciones).toFixed(2));

        let newNeto = calculoProporcion(
          Number(netoNC.toFixed(2)),
          compra.porcentajes.porcNeto,
        );

        let new21 = calculoProporcion(
          Number(iva21NC.toFixed(2)),
          compra.porcentajes.porcIVA21,
        );

        let new105 = calculoProporcion(
          Number(iva105NC.toFixed(2)),
          compra.porcentajes.porcIVA105,
        );
        let newPercep = calculoProporcion(
          Number(percepNC.toFixed(2)),
          compra.porcentajes.porcPercepcion,
        );

        data.push({
          id_nota_credito: nc.compra,
          id_compra: compra.compra,
          //Estos montos son los montos que se restan de las nc por cada compra
          neto: newNeto,
          iva_21: new21,
          iva_10: new105,
          percepcion: newPercep,
          total: Number((newNeto + new21 + new105 + newPercep).toFixed(2)),
          //Porcentajes que se aplican
          porcentaje_neto: Number(
            Number(compra.porcentajes.porcNeto).toFixed(2),
          ),
          porcentaje_iva_21: Number(
            Number(compra.porcentajes.porcIVA21).toFixed(2),
          ),
          porcentaje_iva_10: Number(
            Number(compra.porcentajes.porcIVA105).toFixed(2),
          ),
          porcentaje_percepciones: Number(
            Number(compra.porcentajes.porcPercepcion).toFixed(2),
          ),
        });
      },
    );
  });

  return data;
};

const calculoProporcion = (montoNC, porcentaje) => {
  let value = Number(((montoNC * porcentaje) / 100).toFixed(2));
  return value;
};

const dataPropPagoCompra = (useComprasNC) => {
  let data = [];

  useComprasNC.newCompras.forEach((c) => {
    data.push({
      id_compra: c.compra,
      neto: Number(
        (
          (Number(Number(c.newMontos.neto).toFixed(2)) * c.porcPago) /
          100
        ).toFixed(2),
      ),
      iva_21: Number(
        (
          (Number(Number(c.newMontos.IVA21).toFixed(2)) * c.porcPago) /
          100
        ).toFixed(2),
      ),
      iva_10: Number(
        (
          (Number(Number(c.newMontos.IVA105).toFixed(2)) * c.porcPago) /
          100
        ).toFixed(2),
      ),
      percepcion: Number(
        (
          (Number(Number(c.newMontos.percepciones).toFixed(2)) * c.porcPago) /
          100
        ).toFixed(2),
      ),
      total: Number(
        (
          (Number(Number(c.newMontos.total).toFixed(2)) * c.porcPago) /
          100
        ).toFixed(2),
      ),
    });
  });

  return data;
};

export const getTotalesSelection = (selection) => {
  const copySelection = selection.slice();
  let totalComprasSelection = 0;
  let totalNotasCreditoSelection = 0;

  copySelection.forEach((x) => {
    if (x.tipo_comprobante.nombre === "Notas de Crédito") {
      totalNotasCreditoSelection =
        totalNotasCreditoSelection + Number(x.monto_total);
    } else {
      totalComprasSelection = totalComprasSelection + Number(x.deuda_restante);
    }
  });

  return Number(
    (
      Number(totalComprasSelection.toFixed(2)) -
      Number(totalNotasCreditoSelection.toFixed(2))
    ).toFixed(2),
  );
};

export const getIsParcial = (useComprasNC, dataGral) => {
  let isParcial = true;
  //Primero validamos el switch de pago total
  //Si es true entonces retorna false ya que es un pago total
  if (dataGral.pagoCompleto) {
    isParcial = false;
  } else {
    //Si es false se debe validar si la sumatoria de montos a pagar de las compras es igual a la sumatoria total de cada compra.
    //Si esto se da significa que el pago es total
    let sumaMontoPagar = 0;
    let sumaTotal = 0;

    useComprasNC.newCompras.forEach((c) => {
      sumaMontoPagar =
        sumaMontoPagar + Number(Number(c.montoAPagar).toFixed(2));
      sumaTotal = sumaTotal + Number(Number(c.newMontos.total).toFixed(2));
    });

    if (Number(sumaMontoPagar.toFixed(2)) === Number(sumaTotal.toFixed(2))) {
      isParcial = false;
    }
  }

  return isParcial;
};

const getInfoMediosPago = (medios) => {
  let dataMedios = [];

  medios.forEach((m) => {
    dataMedios.push({
      id_medio_pago: m.medioSelected,
      monto_medio_pago: Number(Number(m.monto).toFixed(2)),
      coeficiente: {
        id_coeficiente_interes:
          m.medioSelected === 1 || m.medioSelected === 3
            ? m.coeficienteSelected
            : null,
        nroLote:
          m.medioSelected === 1 || m.medioSelected === 3 ? m.nroLote : null,
        referencia:
          m.medioSelected === 1 || m.medioSelected === 3
            ? m.referenciaTarjeta
            : null,
        nroAut:
          m.medioSelected === 1 || m.medioSelected === 3 ? m.nroAut : null,
      },
      transferencia: {
        banco: m.medioSelected === 7 ? m.bancoTransf : null,
        referencia: m.medioSelected === 7 ? m.referenciaTransf : null,
        fecha_transferencia:
          m.medioSelected === 7 ? m.fecha_transferencia : null,
      },
      cheque: {
        numero_cheque: m.medioSelected === 6 ? m.nroCheque : null,
        tipo: m.medioSelected === 6 ? m.tipoCheque : null,
        emision: m.medioSelected === 6 ? m.fechaEmisionCheque : null,
        vencimiento: m.medioSelected === 6 ? m.fechaVencimientoCheque : null,
        cobro: m.medioSelected === 6 ? m.fechaCobroCheque : null,
        monto:
          m.medioSelected === 6 ? Number(Number(m.monto).toFixed(2)) : null,
        referencia: m.medioSelected === 6 ? m.referenciaCheque : null,
        propio: m.medioSelected === 6 ? m.propioCheque : false,
        modalidad: m.medioSelected === 6 ? m.modalidadCheque : null,
        id_cheque_existente:
          m.medioSelected === 6 && m.chequeExistente
            ? m.idChequeExistente
            : null,
        observacion_cheque_existente:
          m.medioSelected === 6 && m.chequeExistente
            ? m.observacionChequeExistente
            : "",
      },
    });
  });

  return dataMedios;
};

//Funcion que obtiene los montos totales de todas las compras
const getDataMontos = (useComprasNC) => {
  let monto_base_pago = 0;
  let monto_iva_21_pago = 0;
  let monto_iva_10_pago = 0;
  let monto_percepciones_pago = 0;

  useComprasNC.newCompras.forEach((item) => {
    monto_base_pago =
      monto_base_pago +
      Number(item.newMontos.neto) * (Number(item.porcPago) / 100);
    monto_iva_21_pago =
      monto_iva_21_pago +
      Number(item.newMontos.IVA21) * (Number(item.porcPago) / 100);
    monto_iva_10_pago =
      monto_iva_10_pago +
      Number(item.newMontos.IVA105) * (Number(item.porcPago) / 100);
    monto_percepciones_pago =
      monto_percepciones_pago +
      Number(item.newMontos.percepciones) * (Number(item.porcPago) / 100);
  });

  return {
    monto_base_pago: Number(monto_base_pago.toFixed(2)),
    monto_iva_21_pago: Number(monto_iva_21_pago.toFixed(2)),
    monto_iva_10_pago: Number(monto_iva_10_pago.toFixed(2)),
    monto_percepciones_pago: Number(monto_percepciones_pago.toFixed(2)),
  };
};
