export const TIPO_COEFICIENTE = {
  PORCENTAJE_DESCUENTO: "porcentaje_descuento",
  PORCENTAJE_CONTADO: "porcentaje_contado",
  PORCENTAJE_LISTA: "porcentaje_lista",
};

export function getTipoCoeficiente({ tipo_coeficiente } = {}) {
  if (tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_DESCUENTO) {
    return "descuento";
  }
  if (tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_CONTADO) {
    return "recargo contado";
  }
  if (tipo_coeficiente === TIPO_COEFICIENTE.PORCENTAJE_LISTA) {
    return "recargo lista";
  }
}

export function getTituloModal({
  marca,
  repuesto_proveedor,
  parametros_proveedor,
  tipo_coeficiente,
} = {}) {
  if (marca) {
    return `Cargar ${getTipoCoeficiente({ tipo_coeficiente })} en ${marca.nombre}`;
  }
  if (repuesto_proveedor) {
    return `Cargar ${getTipoCoeficiente({ tipo_coeficiente })}`;
  }
  if (parametros_proveedor) {
    return `Cargar ${getTipoCoeficiente({ tipo_coeficiente })}`;
  }
  return `Descuento y Recargo`;
}

/**
 * Retorna un valor en hexadecimal aleatorio, de tamaño `size`.
 */
export const getRanHex = (size) => {
  let result = [];
  let hexRef = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
  ];

  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return result.join("");
};
