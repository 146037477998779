import React from "react";
import { Box, Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function FormularioNuevoCoeficiente({
  formik,
  createDescuentoYRecargo,
}) {
  return (
    <Box
      display="flex"
      justifyContent={"center"}
      style={{ gap: 4, width: "100%", alignItems: "center" }}
    >
      <TextField
        fullWidth
        variant="outlined"
        label="Nombre"
        size="small"
        name="nombre"
        autoComplete="off"
        value={formik.values.nombre}
        onChange={formik.handleChange}
        error={Boolean(formik.touched.nombre && formik.errors.nombre)}
        helperText={formik.touched.nombre && formik.errors.nombre}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Coeficiente"
        size="small"
        name="coeficiente"
        autoComplete="off"
        value={formik.values.coeficiente}
        onChange={(e) => {
          const { value } = e.target;
          if (/^\d*\.?\d{0,2}$/.test(value)) {
            formik.handleChange(e);
          }
        }}
        error={Boolean(formik.touched.coeficiente && formik.errors.coeficiente)}
        helperText={formik.touched.coeficiente && formik.errors.coeficiente}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      />
      <Box>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
          disabled={
            createDescuentoYRecargo.isLoading ||
            createDescuentoYRecargo.isFetching
          }
        >
          Agregar
        </Button>
      </Box>
    </Box>
  );
}
