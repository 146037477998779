import React from "react";
import { Box, TableCell, Tooltip, Typography } from "@material-ui/core";
export const StockDisponible = ({ articulo }) => {
  return (
    <span
      style={{
        color: articulo
          ? articulo.stockDisponible > 0
            ? "green"
            : "red"
          : "black",
        fontWeight: "bold",
      }}
    >
      {articulo ? articulo.stockDisponible : "---"}
    </span>
  );
};

export const StockDeseado = ({ articulo }) => {
  return (
    <span
      style={{
        color: articulo
          ? articulo.stockDisponible > 0
            ? "green"
            : "red"
          : "black",
        fontWeight: "bold",
      }}
    >
      {articulo ? articulo.stock_deseado : "---"}
    </span>
  );
};

const StockDisponibleSucursal = ({ stock }) => {
  return (
    <span
      style={{
        color: stock !== null ? (stock > 0 ? "green" : "red") : "black",
        fontWeight: "bold",
      }}
    >
      {stock !== null ? stock : "---"}
    </span>
  );
};

export const StockSucursales = ({ item, sucursales }) => {
  if (
    item.repuesto_sucursal &&
    item.repuesto_sucursal.codigo_vinculacion_sucursal
  ) {
    return sucursales.map((s, i) => {
      const stockSucursal = item.repuesto_sucursal.sucursales.find(
        (i) => i.sucursal_id === s.id,
      );

      return (
        <TableCell align="center" key={i}>
          <StockDisponibleSucursal
            stock={stockSucursal && stockSucursal.stock}
          />
        </TableCell>
      );
    });
  } else {
    return sucursales.map((s, i) => {
      return (
        <TableCell align="center" key={i}>
          <span style={{ color: "black", fontWeight: "bold" }}>---</span>
        </TableCell>
      );
    });
  }
};

export const ToolTipDescuentoRecargo = ({ data, children }) => {
  const Message = (
    <Box>
      {data.map((item, index) => {
        return (
          <>
            <Typography key={index} variant="body1">
              {`${item.nombre} de ${item.origen}: ${Number(item.porcentaje).toFixed(2)}%`}
            </Typography>
            {item.descuentos_recargos_informativos.map(
              (descuento_recargo, index) => (
                <Typography key={index} variant="body2">
                  {`- ${descuento_recargo.nombre}: ${Number(descuento_recargo.coeficiente).toFixed(2)}%`}
                </Typography>
              ),
            )}
          </>
        );
      })}
    </Box>
  );

  return (
    <Tooltip title={Message} placement={"left"}>
      <div>
        <i
          className="material-icons info"
          style={{ fontSize: 14, cursor: "pointer" }}
        >
          info
        </i>
        {children && children}
        <span />
      </div>
    </Tooltip>
  );
};
