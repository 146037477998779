import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import AutocompleteProveedor from "../../../components/ait-reusable/AutocompleteProveedor";
import ImagenesArticuloConBoton from "../../../components/ait-reusable/ImagenesArticuloConBoton/ImagenesArticuloConBoton";
import ModalExplicacionManejoUnidadesArticulo from "../../../components/ait-reusable/ModalExplicacionManejoUnidadesArticulo";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import {
  getDescuentoProducto,
  observacionArticulo,
} from "../../../requests/urls";
import { getProveedores } from "./utils";
import { useMarcas } from "../../../customHooks/useMarcas";
import request from "../../../requests/request";
import ModalAgregarMarca from "../Modales/ModalAgregarMarca/ModalAgregarMarca";
import FormCalcularPrecios from "./formCalcularPrecios";
import ModalObservacionarticulo from "../../Proveedores/Catalogo/Modales/ModalObservacionArticulo";
import RubrosInputs from "./RubrosInputs";
import { FEATURE_FLAGS } from "../../../constantes/featureFlags";
import useDisclosure from "../../../customHooks/useDisclosure";
import useFeatureFlag from "../../../customHooks/useFeatureFlag";
import ModalPrevisualizarArticulo from "../Modales/ModalPrevisualizarArticulo";
import DescuentoYRecargos from "./DescuentoYRecargos";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heightSelect: {
    minWidth: "234px",
    maxWidth: "234px",
  },
}));

export default function FormArticulo({
  edit,
  isOk,
  articulo,
  ubicacion,
  setArticulo,
  getArticulo,
  getParamProveedor,
  loadingForm,
  ...props
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [transition, settransition] = useState(0);
  const [obsArticulo, setObsArticulo] = useState([]);
  const [idProveedor, setIdProveedor] = useState(null);
  const [modalAddMarca, setModalAddMarca] = useState(false);
  const [precioDeseado, setPrecioDeseado] = useState("");
  const [marcaSelected, setMarcaSelected] = useState(null);
  const [recargoSugerido, setRecargoSugerido] = useState(null);
  const [listProveedores, setlistProveedores] = useState([]);
  const [modalObservacion, setModalObservacion] = useState(false);
  const [descuentoSugerido, setDescuentoSugerido] = useState(null);
  const [proveedorSelected, setProveedorSelected] = useState(null);
  const [recargarDespuesDeSubmit, setRecargarDespuesDeSubmit] = useState(false);
  const [tipoDescuento, setTipoDescuento] = useState(null);

  const [
    isOpenMoldaPrevisualizar,
    { open: openModalPrevisualizar, close: closeModalPrevisualizar },
  ] = useDisclosure(false);

  const { isFeatureEnabled } = useFeatureFlag();

  const { marcas, loading } = useMarcas({
    proveedor: edit ? idProveedor : idProveedor || 0,
  });

  const handleGetProveedores = () => {
    setlistProveedores([]);
    getProveedores().then((res) => setlistProveedores(res));
  };

  const handleSubmitObservaciones = async (obs) => {
    setObsArticulo(obs);
    setModalObservacion(false);
  };

  const newDataAutocomplete = (data) => {
    let newData = {};

    if (Object.keys(data).length > 0) {
      let keys = Object.keys(data);
      keys.forEach((key) => {
        if (key === "idProveedor") {
          newData.razonSocial = data[key];
        } else {
          newData[key] = data[key];
        }
      });
    }

    return newData;
  };

  const handleOpenModalAddMarca = (proveedor) => {
    setModalAddMarca(true);
    setProveedorSelected(proveedor);
  };

  const handleSetPrecioCosto = (e, values) => {
    setPrecioDeseado(e.target.value);

    let precioDeseado = Number(e.target.value);
    let precioCosto = values.precioCosto === "" ? 0 : values.precioCosto;

    if (precioDeseado < precioCosto) {
      let descuentoNecesario = precioDeseado - precioCosto;
      let descuentoEnPorcentaje =
        precioCosto === 0
          ? 0
          : (((descuentoNecesario * 100) / precioCosto) * -1).toFixed(2);

      setRecargoSugerido(null);
      setDescuentoSugerido(descuentoEnPorcentaje);
    } else if (precioDeseado > precioCosto) {
      let recargoNecesario = precioCosto - precioDeseado;
      let recargoEnPorcentaje =
        precioCosto === 0
          ? 0
          : (((recargoNecesario * 100) / precioCosto) * -1).toFixed(2);
      setDescuentoSugerido(null);
      setRecargoSugerido(recargoEnPorcentaje);
    } else if (precioCosto === precioDeseado || precioDeseado === "") {
      setDescuentoSugerido(null);
      setRecargoSugerido(null);
    }
  };

  const onChangeManejaUnidades = (e, setFieldValue) => {
    setFieldValue("maneja_unidades", e.target.checked);
    if (!e.target.checked) {
      setFieldValue("cantidad_unidades", 1);
    }
  };

  const getDescuentoData = async () => {
    try {
      let idMarca = marcaSelected ? marcaSelected.id : null;
      const response = await request({
        method: "GET",
        url: getDescuentoProducto(articulo.idProveedor, idMarca),
      });
      if (response.status === 200) {
        let value = response.data;
        setTipoDescuento(value.data);
      } else {
        setTipoDescuento(null);
      }
    } catch (err) {
      setTipoDescuento(null);
    }
  };

  useEffect(() => {
    if (marcas.length > 0 && articulo.marca) {
      let selected = marcas.filter((m) => m.id === articulo.marca)[0];
      setMarcaSelected(selected);
    }
  }, [marcas]);

  useEffect(() => {
    if (articulo.idProveedor) {
      setIdProveedor(articulo.idProveedor);
      getDescuentoData();
    }
  }, [articulo.idProveedor]);

  useEffect(() => {
    setObsArticulo(articulo.observaciones);
    handleGetProveedores();
  }, []);

  useEffect(() => {
    if (articulo.idUbicacion) props.setAddUbicacion(true);
  }, [articulo.idUbicacion]);

  useEffect(() => {
    setObsArticulo(articulo.observaciones);
  }, [articulo]);

  useEffect(() => {
    getDescuentoData();
  }, [marcaSelected]);

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={open || loading}
        onClick={() => setOpen(true)}
        transitionDuration={transition}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...articulo,
        }}
        validationSchema={Yup.object().shape({
          idProveedor: Yup.number()
            .required("Debe escoger un proveedor e ingresar un código")
            .typeError("Debe escoger un proveedor"),

          // Si es una edicion no se pide como obligatorio
          // se bloquea el campo para que no se pueda editar para evitar duplicados
          // existen repuesto sin codigo de proveedor, que provienen de las listas de precios
          codProveedor: edit
            ? Yup.string()
            : Yup.string().required("Debe ingresar un código"),

          descripcionProveedor: Yup.string().required(
            "Debe ingresar una descripción",
          ),
          cantidad_unidades: Yup.number().when("maneja_unidades", {
            is: true,
            then: Yup.number()
              .required("Ingrese la cantidad de unidades")
              .min(1, "La cantidad de unidades debe ser mayor o igual a 1"),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            props.handleSubmit(
              values,
              true,
              obsArticulo,
              marcaSelected,
              recargarDespuesDeSubmit,
            );
          } catch (err) {
            setErrors({ submit: err.message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form
            style={{ width: "100%", paddingTop: "10px" }}
            onSubmit={handleSubmit}
            {...props}
          >
            <Card className="mb-4 p-4" style={{ marginTop: "0px" }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  container
                  xs={12}
                  md={8}
                  lg={8}
                  spacing={1}
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Grid item xs={12}>
                    <label className="fontBold">Proveedor: *</label>{" "}
                    <FormGroup row>
                      <AutocompleteProveedor
                        proveedorSelect={
                          listProveedores.filter(
                            (p) => p.idProveedor === values.idProveedor,
                          )[0]
                        }
                        touched={newDataAutocomplete(touched)}
                        errors={newDataAutocomplete(errors)}
                        handleBlur={handleBlur}
                        handleOnChangeProveedor={(prov) => {
                          let newIdProv = prov ? prov.idProveedor : "";
                          setFieldValue("idProveedor", newIdProv);
                          setIdProveedor(newIdProv);
                          getParamProveedor(newIdProv, setFieldValue);
                          setFieldValue(
                            "identificador_proveedor",
                            prov ? prov.identificador : "",
                          );
                        }}
                        disabled={edit}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={values.idProveedor ? 10 : 12}>
                    <label className="fontBold">Marca:</label>
                    <Autocomplete
                      fullWidth
                      getOptionLabel={(x) => String(x.nombre)}
                      options={marcas}
                      value={marcaSelected}
                      onChange={(_event, value) => {
                        if (value) {
                          setMarcaSelected(value);
                        } else {
                          setMarcaSelected(null);
                        }
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          variant="outlined"
                        />
                      )}
                      noOptionsText="No existen marcas"
                    />
                  </Grid>

                  {values.idProveedor ? (
                    <Grid item xs={2}>
                      <Avatar
                        variant="rounded"
                        title="Agregar nuevo proveedor"
                        style={{
                          backgroundColor: "rgb(0, 123, 255)",
                          cursor: "pointer",
                          fontSize: 25,
                          fontWeight: "bold",
                          width: "100%",
                        }}
                        onClick={() =>
                          handleOpenModalAddMarca(values.idProveedor)
                        }
                      >
                        +
                      </Avatar>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item xs={12} md={4}>
                  <ImagenesArticuloConBoton articulo={values} />
                </Grid>

                <Grid item container xs={12} spacing={1}>
                  <RubrosInputs values={values} setFieldValue={setFieldValue} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <label className="fontBold">Descripción: *</label>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Descripción del artículo *"
                    name="descripcionProveedor"
                    id="descripcion"
                    inputProps={{
                      maxLength: 2000,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    error={Boolean(
                      touched.descripcionProveedor &&
                        errors.descripcionProveedor,
                    )}
                    helperText={
                      touched.descripcionProveedor &&
                      errors.descripcionProveedor
                    }
                    value={values.descripcionProveedor}
                  />
                </Grid>

                <Grid item container xs={12}>
                  <label className="fontBold">Códigos: *</label>
                  <Grid item container xs={12} alignItems="center" spacing={1}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Box pb={1}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          name="codProveedor"
                          id="codProveedor"
                          label="Código artículo*"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codProveedor}
                          type="text"
                          autoComplete="off"
                          inputProps={{
                            maxLength: 50,
                          }}
                          error={Boolean(
                            touched.codProveedor && errors.codProveedor,
                          )}
                          helperText={
                            touched.codProveedor && errors.codProveedor
                          }
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <Box pb={1}>
                        <TextField
                          label="Código original"
                          type="text"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="codOriginal"
                          id="codOriginal"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codOriginal}
                          autoComplete="off"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <Box pb={1}>
                        <TextField
                          label="Código auxiliar"
                          type="text"
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="codAuxiliar"
                          id="codAuxiliar"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.codAuxiliar}
                          autoComplete="off"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box py={2}>
                    <Divider />
                  </Box>
                </Grid>

                {/* {tipoDescuento && !values.aplica_descuento ? (
                  <Grid xs={12} item>
                    <AlertReusable
                      severity={"info"}
                      text={
                        tipoDescuento +
                        " pero podés evitarlo activando descuentos/recargos específicos para este artículo"
                      }
                    />
                  </Grid>
                ) : null} */}

                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  style={{ marginTop: "1rem" }}
                >
                  <FormCalcularPrecios
                    values={values}
                    loading={() => {
                      setOpen(true);
                      settransition(0);
                    }}
                    updatePrecios={(precios) => {
                      setArticulo({
                        ...values,
                        precioLista: precios.precioLista,
                        precioVenta: precios.precioVenta,
                        precioCosto: precios.precioCosto,
                      });
                      settransition(500);
                      setOpen(false);
                    }}
                    edit={edit}
                    setFieldValue={setFieldValue}
                  />
                </Grid>

                <DescuentoYRecargos
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                <Grid item xs={12} sm={3} lg={3}>
                  <label className="fontBold">P. costo:</label>
                  <Box>
                    <TextField
                      disabled={values.is_precio_lista}
                      placeholder="0.00"
                      type="number"
                      name="precioCosto"
                      fullWidth
                      variant="outlined"
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.precioCosto}
                      autoComplete="off"
                      InputProps={{
                        startAdornment: <Box pr={1}>$</Box>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={3} lg={3}>
                  <label className="fontBold">
                    {values.is_precio_lista
                      ? "P. Lista (Sin IVA)"
                      : "P.Lista (Con IVA)"}
                    :
                  </label>
                  <Box>
                    <TextField
                      disabled={!values.is_precio_lista}
                      placeholder="0.00"
                      type="number"
                      size="small"
                      variant="outlined"
                      fullWidth
                      name="precioLista"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.precioLista}
                      autoComplete="off"
                      InputProps={{
                        startAdornment: <Box pr={1}>$</Box>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={3} lg={3}>
                  <label className="fontBold">P. Venta:</label>
                  <Box>
                    <TextField
                      disabled
                      placeholder="0.00"
                      type="number"
                      name="precioVenta"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.precioVenta}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: "%",
                      }}
                    />
                  </Box>
                </Grid>

                {!edit ? (
                  <Grid item xs={12} sm={3} lg={3}>
                    <label className="fontBold">
                      ¿A cuánto lo querés vender?
                    </label>
                    <Box>
                      <TextField
                        placeholder="0.00"
                        type="number"
                        name="precioDeseado"
                        variant="outlined"
                        fullWidth
                        size="small"
                        onChange={(e) => handleSetPrecioCosto(e, values)}
                        onBlur={handleBlur}
                        value={precioDeseado}
                        autoComplete="off"
                        InputProps={{
                          startAdornment: <Box pr={1}>$</Box>,
                        }}
                      />

                      {descuentoSugerido ? (
                        <Typography
                          style={{ color: "#17c671" }}
                          variant="subtitle"
                        >
                          Descuento sugerido para el precio costo{" "}
                          {descuentoSugerido}%
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setFieldValue("descuento", descuentoSugerido)
                            }
                          >
                            APLICAR
                          </span>
                        </Typography>
                      ) : null}

                      {recargoSugerido ? (
                        <Typography
                          style={{ color: "#17c671" }}
                          variant="subtitle"
                        >
                          Recargo sugerido para el precio de venta{" "}
                          {recargoSugerido}%
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setFieldValue("recargo_contado", recargoSugerido)
                            }
                          >
                            APLICAR
                          </span>
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={3} lg={3}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "end",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Button
                      startIcon={<LocalAtmIcon />}
                      onClick={openModalPrevisualizar}
                      disabled={loadingForm}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      style={{ height: "40px" }}
                    >
                      Previsualizar precios
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Como el proveedor y la marca pueden tener descuentos y/o
                    recargos, los precios que ingreses no siempre serán los
                    finales. Hacé clic en "Previsualizar Precios" para ver los
                    precios ajustados.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box py={2}>
                    <Divider />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3} lg={3}>
                  <Box>
                    <label className="fontBold">Stock:</label>
                    <TextField
                      disabled={edit}
                      placeholder="0"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      label={edit ? "Stock Disponible" : "Stock Inicial"}
                      name="stockDisponible"
                      onChange={handleChange}
                      value={values.stockDisponible}
                      autoComplete="off"
                    />
                  </Box>
                </Grid>

                <Grid xs={12} item sm={3} lg={3}>
                  <label className="fontBold">Stock Deseado:</label>
                  <TextField
                    placeholder="0"
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    //disabled={edit}
                    label="Stock deseado"
                    name="stock_deseado"
                    onChange={handleChange}
                    value={values.stock_deseado || ""}
                    autoComplete="off"
                  />
                </Grid>

                <Grid item style={{ display: "inline-flex", paddingTop: 5 }}>
                  <label className="fontBold mt-2 pr-2">
                    ¿Agregar ubicación?
                  </label>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={props.addUbicacion}
                        onChange={() => {
                          props.setAddUbicacion(!props.addUbicacion);
                          props.modal(props.addUbicacion);
                        }}
                      />
                    }
                    label={!props.addUbicacion ? "No" : "Si"}
                  />
                </Grid>
                {isFeatureEnabled(FEATURE_FLAGS.ARTICULO_UNIDADES) && (
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    style={{
                      marginTop: 20,
                      backgroundColor: "rgb(232, 244, 253)",
                    }}
                  >
                    <Grid item xs={12}>
                      <Alert severity="info">
                        <Box display={"flex"} alignItems={"center"}>
                          Funcionalidad{" "}
                          <strong style={{ fontWeight: "bolder" }}>BETA</strong>
                          <ModalExplicacionManejoUnidadesArticulo />
                        </Box>
                      </Alert>
                    </Grid>
                    {values.maneja_unidades_inicialmente && (
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          La edición de la cantidad de unidades se encuentra
                          deshabilitada. Ser podrá relizar en proximas
                          versiones.
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                          <Switch
                            disabled={values.maneja_unidades_inicialmente}
                            checked={values.maneja_unidades}
                            onChange={(e) =>
                              onChangeManejaUnidades(e, setFieldValue)
                            }
                            name="maneja_unidades"
                            color="primary"
                          />
                        }
                        label="Manejar unidades"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        placeholder="Cantidad de unidades"
                        type="number"
                        size="small"
                        name="cantidad_unidades"
                        variant="outlined"
                        autoComplete="off"
                        value={values.cantidad_unidades}
                        onChange={handleChange}
                        disabled={
                          values.maneja_unidades &&
                          !values.maneja_unidades_inicialmente
                            ? false
                            : true
                        }
                        InputProps={{
                          style: {
                            backgroundColor: "white",
                          },
                        }}
                        error={Boolean(
                          touched.cantidad_unidades && errors.cantidad_unidades,
                        )}
                        helperText={
                          touched.cantidad_unidades && errors.cantidad_unidades
                        }
                      />
                    </Grid>

                    {values.maneja_unidades && (
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Los precios por unidad querian de la siguiente manera:
                        </Typography>
                        <Typography variant="body2">
                          Precio de costo: $
                          {(
                            Number(values.precioCosto) /
                            Number(values.cantidad_unidades)
                          ).toFixed(2)}
                        </Typography>
                        <Typography variant="body2">
                          Precio de venta: $
                          {(
                            Number(values.precioVenta) /
                            Number(values.cantidad_unidades)
                          ).toFixed(2)}
                        </Typography>
                        <Typography variant="body2">
                          Precio de lista: $
                          {(
                            Number(values.precioLista) /
                            Number(values.cantidad_unidades)
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}

                <Grid container style={{ paddingTop: 20 }}>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Button
                      style={{
                        outline: "none",
                        backgroundColor: "#007bff",
                        color: "#fff",
                      }}
                      disabled={null}
                      onClick={() => setModalObservacion(true)}
                    >
                      AGREGAR OBSERVACIÓN
                    </Button>
                  </Grid>

                  <Grid
                    item
                    spacing={8}
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={() => {
                        setRecargarDespuesDeSubmit(true);
                        handleSubmit();
                      }}
                      disabled={loadingForm}
                      style={{
                        marginRight: 16,
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Guardar cambios
                    </Button>
                    <Button
                      style={{
                        outline: "none",
                        backgroundColor: "#17c671",
                        color: "#fff",
                      }}
                      onClick={() => {
                        setRecargarDespuesDeSubmit(false);
                        handleSubmit();
                      }}
                      disabled={loadingForm}
                    >
                      {edit ? "GUARDAR Y FINALIZAR" : " REGISTRAR"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            {isOpenMoldaPrevisualizar && (
              <ModalPrevisualizarArticulo
                open={isOpenMoldaPrevisualizar}
                close={closeModalPrevisualizar}
                articulo_original={articulo}
                articulo_editado={values}
                marcaSelected={marcaSelected}
              />
            )}
          </form>
        )}
      </Formik>

      {modalObservacion && (
        <ModalObservacionarticulo
          open={modalObservacion}
          obsArticulo={obsArticulo}
          handleClose={() => setModalObservacion(false)}
          onSubmitObservaciones={handleSubmitObservaciones}
        />
      )}

      {modalAddMarca ? (
        <ModalAgregarMarca
          open={modalAddMarca}
          proveedor={proveedorSelected}
          setMarcaSelected={setMarcaSelected}
          handleClose={() => setModalAddMarca(false)}
          handleGetProveedores={() => handleGetProveedores()}
        />
      ) : null}
    </div>
  );
}
