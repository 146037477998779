import {
  getConData,
  getSimples,
  postSimple,
} from "../../../utils/peticionesBack";
import { actualizarPrecio } from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";

export const actualizarPrecios = async (
  idArticuloProveedor,
  idArticulo,
  articulos,
  // Si el back debe devolver el precio fraccionado en caso el articulo maneje unidades debe ser true
  fraccionar_precio = false,
  // Si el back debe devolver los descuentos y recargos informativos debe ser true
  incluir_descuentos_recargos_informativos = false,
) => {
  let url = actualizarPrecio;
  let precios;
  let nuevosArt = articulos.slice();
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
    fraccionar_precio: Number(fraccionar_precio),
    incluir_descuentos_recargos_informativos: Number(
      incluir_descuentos_recargos_informativos,
    ),
  };

  await getConData(url, { params })
    .then((res) => {
      precios = res.data.data;
      nuevosArt.forEach((articulo) => {
        if (articulo.idRepuestoProveedor === idArticuloProveedor) {
          articulo.precios.venta = parseFloat(precios.venta);
          articulo.precios.costo = parseFloat(precios.costo);
          articulo.precios.lista = parseFloat(precios.lista);
        }
      });
    })
    .catch((err) => {
      errorNotification("Error al actualizar el precio del articulo ");
    });

  return nuevosArt;
};

//Funcion que actualiza el repuesto y retorna todos los articulos y el repuesto actualizado.
export const getArticuloActualizado = async (
  idArticuloProveedor,
  idArticulo,
  articulos,
  fraccionar_precio = false,
  lista_de_precios,
) => {
  let url = actualizarPrecio;
  let precios;
  let nuevosArt = articulos.slice();
  let artSelect;
  let params = {
    idArticulo: idArticulo,
    idArticuloProveedor: idArticuloProveedor,
    fraccionar_precio: Number(fraccionar_precio),
    listaPrecios: lista_de_precios,
  };

  await getConData(url, { params })
    .then((res) => {
      precios = res.data.data;
      nuevosArt.forEach((articulo) => {
        if (articulo.idRepuestoProveedor === idArticuloProveedor) {
          articulo.precios.costo = parseFloat(precios.costo);
          articulo.precios.lista = parseFloat(precios.lista);
          articulo.precios.venta = parseFloat(precios.venta);
          articulo.idListaPrecio = lista_de_precios;
          artSelect = articulo;
        }
      });
    })
    .catch((err) => {
      console.log(err);
      errorNotification("Error al actualizar el precio del articulo.");
    });

  let respuesta = { art: artSelect, nuevos: nuevosArt };
  return respuesta;
};

export const getUbicaciones = async (idDeposito, idZona, idEstante) => {
  let respuesta = { zonas: [], estantes: [], filas: [] };
  await getSimples(`/api/ubicaciones/?padre=${idDeposito}`).then((res) => {
    if (res.data.err_code === 200) {
      respuesta.zonas = res.data.data;
    }
  });
  await getSimples(`/api/ubicaciones/?padre=${idZona}`).then((res) => {
    if (res.data.err_code === 200) {
      respuesta.estantes = res.data.data;
    }
  });
  await getSimples(`/api/ubicaciones/?padre=${idEstante}`).then((res) => {
    if (res.data.err_code === 200) {
      respuesta.filas = res.data.data;
    }
  });
  return respuesta;
};

export const validarFormRepuesto = async (
  formArt,
  invalidFormArt,
  ubiSinDefinir,
) => {
  let keys = Object.keys(formArt);
  let form = formArt;
  let invalidForm = invalidFormArt;
  let validForm = true;

  if (ubiSinDefinir === false) {
    keys.forEach((key) => {
      if (
        key !== "idDeposito" &&
        key !== "idEstante" &&
        key !== "idZona" &&
        key !== "idUbicacion" &&
        key !== "manejaStock" &&
        key !== "activo" &&
        key !== "stock_deseado" &&
        key !== "maneja_unidades"
      ) {
        if (form[key] === "") {
          invalidForm[key] = true;
          validForm = false;
        }
        if (key === "cantidad_unidades" && Number(form[key]) <= 0) {
          invalidForm[key] = true;
          validForm = false;
        }
      }
    });
  } else {
    keys.forEach((key) => {
      if (
        key !== "manejaStock" &&
        key !== "activo" &&
        key !== "maneja_unidades"
      ) {
        if (form[key] === "") {
          invalidForm[key] = true;
          validForm = false;
        }
      }
    });
  }

  return { valid: validForm, invalid: invalidForm };
};

export const postAgregarRepuesto = async (ubiSinDefinir, form, artSelect) => {
  let ubicacion;
  let request;
  if (ubiSinDefinir === false) {
    ubicacion = null;
  } else {
    ubicacion = form.idUbicacion;
  }
  let data = {
    articulo: artSelect.idRepuestoProveedor,
    stock: form.stockDisponible,
    stock_deseado: form.stock_deseado,
    precio: form.precioVenta,
    ubicacion: ubicacion,
    maneja_stock: 1,
    maneja_unidades: form.maneja_unidades,
    cantidad_unidades: form.cantidad_unidades,
  };
  console.log(data, form);
  await postSimple(`/api/articulos/agregar/`, data).then((res) => {
    request = res;
  });
  return request;
};

export const actualizarArticulo = async (articuloSelect, articulos) => {
  let nuevos = articulos.slice();
  nuevos.forEach((articulo) => {
    if (articulo.idRepuestoProveedor === articuloSelect.idRepuestoProveedor) {
      if (articuloSelect.repuestoProveedor) {
        articulo.codOriginal = articuloSelect.repuestoProveedor.codOriginal;
        articulo.codProveedor = articuloSelect.repuestoProveedor.codProveedor;
        articulo.descripcionProveedor =
          articuloSelect.repuestoProveedor.descripcionProveedor;
        articulo.fechaUltimaModificacion =
          articuloSelect.repuestoProveedor.fechaUltimaModificacion;
        articulo.idProveedor = articuloSelect.idProveedor;
        articulo.idRepuestoProveedor = articuloSelect.idRepuestoProveedor;
        // articulo.is_api = articuloSelect.repuestoProveedor.is_api;
        articulo.modificado = articuloSelect.repuestoProveedor.modificado;
        articulo.precios.costo = articuloSelect.repuestoProveedor.precios.costo;
        articulo.precios.lista = articuloSelect.repuestoProveedor.precios.lista;
        articulo.precios.venta = articuloSelect.repuestoProveedor.precios.venta;
        articulo.proveedor = articuloSelect.proveedor.razonSocial;
        articulo.repuesto_sucursal = {
          ...articuloSelect.repuestoProveedor.repuesto_sucursal,
          codigo_vinculacion_sotck: null,
          sucursales: [],
        };
      }
    }
  });
  return nuevos;
};

/**Funcion que verifica si el repuesto que se quiere agregar a carrito ya esta agregado o no.
 * Retorna False si el repuesto no esta agregado o si el carrito esta vacio.
 * Retorna True si el repuesto esta agregado
 */
export const verificarArticulo = async (articulo, carrito) => {
  let idRepSuc =
    (articulo.repuesto_sucursal &&
      articulo.repuesto_sucursal.idRepuestoSucursal) ||
    "";
  const cartItems = carrito.slice();
  let isOnCart = false;
  let product;

  //Verifica si el carrito tiene repuestos
  if (carrito.length > 0) {
    //Verifica si el repuesto es de sucursal o no
    if (idRepSuc === "") {
      cartItems.forEach((item) => {
        //Verifica si esta en el carrito por idRepuestoProveedor
        if (item.idRepuestoProveedor === articulo.idRepuestoProveedor) {
          isOnCart = true;
          product = item;
        }
      });
    } else {
      cartItems.forEach((item) => {
        //Verifica si esta en el carrito por idRepuestoSucursal
        if (
          item.idRepuestoSucursal ===
          articulo.repuesto_sucursal.idRepuestoSucursal
        ) {
          isOnCart = true;
          product = item;
        }
      });
    }
    if (isOnCart) {
      return {
        valor: true,
        producto: product,
        idRepSuc: idRepSuc,
      };
    } else {
      return { valor: false, producto: "", idRepSuc: idRepSuc };
    }
  } else {
    return { valor: false, producto: "", idRepSuc: idRepSuc };
  }
};

export const agregarNuevosValores = async (
  articulo,
  preVenta,
  cantidad,
  idArtSuc,
) => {
  let art = [];
  art.push({
    idRepuestoSucursal: idArtSuc,
    idRepuestoProveedor: articulo.idRepuestoProveedor,
    repuestoProveedor: {
      codOriginal: articulo.codOriginal,
      codProveedor: articulo.codProveedor,
      descripcionProveedor: articulo.descripcionProveedor,
    },
    tipoIVA: 1,
    contieneIVA: true,
    precioVenta: preVenta,
    precioCosto: articulo.precioCosto,
    proveedor: { razonSocial: articulo.proveedor },
    cantidad: cantidad,
    margen: 0,
    descuento: 0,
  });
  return art[0];
};
